jQuery.noConflict();
(function ($) {
    $(function () {
        var searchOptions = autoblock.options;
        var originalOptions = autoblock.originalOptions;

        $(document).on('change', 'div.popover input[type="checkbox"]', function () {
            var vals = [];
            var text = '';
            text = $(this).parent().text();
            vals.push($(this).val());
            var s = $('li span.title_' + $(this).attr('name'));
            reloadMenu(s, vals, text);
            searchOptions[$(this).attr('name')] = $(this).val();
            $(this).parent().toggleClass("selected");
            selectQueryParams();
            closePopover($(this));
        });

        function reloadMenu(s, vals, text) {
            if (vals.length > 1) {
                $(s).closest('li').addClass('remove');
                $(s).closest('li').attr('data-toggle', '');
                $(s).text('Keli');
            } else if (vals.length == 1) {
                $(s).closest('li').addClass('remove');
                $(s).closest('li').attr('data-toggle', '');
                $(s).text(text);
            } else {
                $(s).closest('li').removeClass('remove');
                $(s).closest('li').attr('data-toggle', 'popover');
                $(s).text('--');
            }
        }

        $(this).on('click', '#search-options li.remove', function (e) {
            var elem = $(this).attr('data-option');
            var arr = [];
            for (var i in searchOptions) {
                if (i !== elem) {
                    arr[i] = searchOptions[i];
                }
            }
            searchOptions = arr;
            var s = $('li span.title_' + elem);
            reloadMenu(s, [], '');
            $('input[name="' + elem + '"]').attr("checked", false);
            $('input[name="' + elem + '"]').parent().removeClass("selected");
            selectQueryParams();
            return false;
        });

        $(document).on('click', 'ul.country-selector li.category-dropdown-item', function () {
            var code = $(this).attr('data-value');
            var identifier = $(this).closest('ul.country-selector').attr('data-target');
            var extraString = '';
            var elem = $('ul.city-selector#' + identifier);

            var str = '<li class="category-dropdown-item" data-value="" data-dropdown="true"><a><span>--</span></a></li>';
            for (var i in autoblock.trans.city[code]) {
                str += '<li class="city-group"><span>' + autoblock.trans.adverts.meta[i] + '</span><ul class="fake-select category-dropdown">';
                for (var j in autoblock.trans.city[code][i]) {
                    str += '<li class="category-dropdown-item" data-value="' + j + '" data-dropdown="true"><a><span>' + autoblock.trans.city[code][i][j] + '</span></a></li>';
                }
                str += '</ul></li>';
            }
            $(elem).closest('.categorySelect').find('input[name="'+elem.attr('name')+'"]').val('');
            $(elem).closest('.categorySelect').find('span.holder').html('--');
            $(elem).html(str);
        });

        $(document).ready(function () {
            $('#search-sidebar').each(function () {
                $(this).find('select').each(function () {
                    reloadSelects($(this));
                });
                reloadMakeModel();
            });
            $('[data-toggle="tooltip"]').tooltip();
        });

        $(document).on('click', 'li[data-dropdown="true"]', function () {
            if($(this).parent('ul').attr('name') == 'country' && searchOptions['city'] !== undefined)
                searchOptions['city'] = '';
            addToSearchOptionsCustom($(this), 'select');
        });

        function addToSearchOptionsCustom(elem, type) {
            var key = 0;
            var name = $(elem).closest('.dropdown').find('ul.dropdown-menu').attr('name');

            var vals = [];
            if (type == 'select') {
                $(elem).closest('.dropdown').find('ul[name="' + name + '"]').each(function () {
                    //text = $(this).find('option:selected').text();
                    if (name == 'make_id[]' || name == 'model_id[]' || name == 'type[]') {
                        if (name == 'make_id[]') {
                            name = 'make_id';
                        } else if (name == 'model_id[]') {
                            name = 'model_id';
                        } else if (name == 'type[]') {
                            name = 'type';
                        }
                        key = $(this).attr('data-group');
                        if (searchOptions[name] != undefined) {
                            vals[key] = searchOptions[name][key];
                            searchOptions[name][key] = $(elem).attr('data-value');
                        } else {
                            vals.push($(elem).attr('data-value'));
                            searchOptions[name] = vals;
                        }
                    }
                    else {
                        if ($(elem).attr('data-value') != undefined) {
                            if ($(elem).attr('data-value') === '') {
                                vals = '';
                            } else {
                                vals = $(elem).attr('data-value');
                            }
                        }
                        searchOptions[name] = vals;
                    }

                    /*if ($(elem).attr('name') == 'make_id[]') {
                        if($(this).closest('ul.search-params-list').find('select[name="model_id[]"]').length > 0) {
                            addToSearchOptionsCustom($(this).closest('ul.search-params-list').find('select[name="model_id[]"]'), 'select');
                        }
                        if(searchOptions['type'] == undefined || searchOptions['type'][key] == undefined) {
                            addToSearchOptionsCustom($(this).closest('ul.search-params-list').find('select[name="type[]"]'), 'select');
                        }
                    }
                    if ($(elem).attr('name') == 'type[]') {
                        addToSearchOptionsCustom($(this).closest('ul.search-params-list').find('select[name="make_id[]"]'), 'select');
                    }*/

                    key++;
                });
                //console.log(searchOptions);
                if (name == 'make_id[]' || name == 'model_id[]' || name == 'type[]') {
                    $('div#popover_content_make').html($('div.popover-content').html());
                }
            } else {
                if (vals == '') {
                    searchOptions[$(elem).attr('name')] = vals;
                }
                if (vals.length > 0) {
                    searchOptions[$(elem).attr('name')] = vals;
                } else if (vals.length == 0) {
                    searchOptions[$(elem).attr('name')][0] = '';
                }
            }
        }

        $(document).on('change', 'div.quick-search-wrapper select', function () {
            var name = $(this).attr('name');
            var popover = $(this).closest('ul.search-options-popover').attr('data-popover');

            var group = $(this).attr('data-group');
            if(group != undefined) {
                $('select[name="' + name + '"][data-group="' + group + '"]').val($(this).val());
            } else {
                $('select[name="' + name + '"]').val($(this).val());
            }
            addToSearchOptions($(this), 'select');
            if (popover == 'make-model') {
                reloadMakeModel();
            } else if (popover == 'type-make') {
                reloadTypeMake();
            } else {
                reloadSelects($(this));
            }
        });

        var startSidebar = function() {
            if($('ul.search-options-popover[data-popover="make-model"]').length > 0) {
                reloadMakeModel();
            }

            if($('ul.search-options-popover[data-popover="type-make"]').length > 0) {
                reloadTypeMake();
            }

            if($('ul.search-options-popover[data-popover="make_id"]').length > 0) {
                reloadSelects($('select[name="make_id[]"]').first())
            }
        }

        /*function stickySidebarReset() {
            var sidebar_containter = $('#search-sidebar');
            var search_rezult = $('#search-results');
            if (sidebar_containter.length > 0) {
                sidebar_containter.css({position: '', top: '', height: ''});
                search_rezult.css({position: '', top: '', height: '', marginLeft: '', minHeight: ''});
            }
        }

        var previousWidth = window.innerWidth;
        var stickySidebarTimeout = null;
        var stickySidebarDo = false;
        function stickySidebar(first) {
            clearTimeout(stickySidebarTimeout);
            stickySidebarTimeout = setTimeout(function() {
                stickySidebarDo = true;
                if (stickySidebarDo) {
                    var sidebar_containter = $('#search-sidebar');
                    if (first || previousWidth < 768 && window.innerWidth > 768 && sidebar_containter.length > 0) {
                        stickySidebarReset();
                        sidebar_containter.stickyMojo({footerID: '.footer', contentID: '#search-results'});
                        window.scrollBy(0, 1);
                        window.scrollBy(0, -1);
                    }
                    previousWidth = window.innerWidth;
                    stickySidebarDo = false;
                }
            }, 300);

        }

        $.when(startSidebar()).done(function() {
            window.removeEventListener('resize', stickySidebar, false);
            stickySidebarReset();
            var sidebar_containter = $('#search-sidebar');
            if(sidebar_containter.length > 0) {
                if ($(window).height() < 670 && $('#search-sidebar').height() > 825) {
                    $('div.rezult-search-fieldsTitle span').hide();
                }
                stickySidebar(true);
                window.addEventListener('resize', stickySidebar, false);
            }
        })*/

        function reloadMakeModel() {
            if (searchOptions != undefined) {
                var s = $('li[data-option="make"] span.clear');
                if (typeof searchOptions['make_id'] == 'undefined') {
                    $(s).html('<span>-- / --</span>&nbsp;&nbsp;<span class="option-icon glyphicon pull-right"></span>');
                } else if ($.isArray(searchOptions['make_id'])) {
                    var html = '';
                    for (var key in searchOptions['make_id']) {
                        html += '<div class="clear selector_' + key + '"><span>' + $('#search-sidebar select[data-type="1"][data-group="' + key + '"]').find('option[value="' + searchOptions['make_id'][key] + '"]').text() + '</span> / ';
                        if (typeof searchOptions['model_id'] != 'undefined') {
                            if (searchOptions['model_id'][key] != '') {
                                html += '<span>' + $('#search-sidebar select[data-type="2"][data-group="' + key + '"]').find('option[value="' + searchOptions['model_id'][key] + '"]').text() + '</span>';
                            } else {
                                html += '<span>--</span>';
                            }
                        }
                        html += '</div>';
                    }
                    html += '<span class="option-icon glyphicon pull-right"></span>';
                    $(s).html(html);
                } else {
                    var html = '';
                    if(s.length) {
                        html += '<span>' + $('#search-sidebar select[data-type="1"][data-group="0"]').find('option[value="' + searchOptions['make_id'] + '"]').text() + ' / ';
                        if ($.isArray(searchOptions['model_id'])) {
                            html += $('#search-sidebar select[data-type="2"][data-group="0"]').find('option[value="' + searchOptions['model_id'][0] + '"]').text() + '</span>';
                        } else {
                            html += $('#search-sidebar select[data-type="2"][data-group="0"]').find('option[value="' + searchOptions['model_id'] + '"]').text() + '</span>';
                        }
                        html += '&nbsp;&nbsp;<span class="option-icon glyphicon pull-right"></span>';
                    } else {
                        s = $('li[data-option="make_id"] span.title_make_id')
                        html += $('#search-options select[name="make_id"]').find('option[value="' + searchOptions['make_id'] + '"]').text();
                    }

                    $(s).html(html);
                }
            }
        }

        function reloadTypeMake() {
            if (searchOptions != undefined) {
                var s = $('li[data-option="type"] span.clear');
                var script = $('li[data-option="type"]').attr('data-script');
                if (typeof searchOptions['type'] == 'undefined' && script == undefined) {
                    $(s).html('<span>-- / --</span>&nbsp;&nbsp;<span class="option-icon glyphicon pull-right"></span>');
                } else if ($.isArray(searchOptions['type'])) {
                    var html = '';
                    for (var key in searchOptions['type']) {
                        html += '<div class="clear selector_' + key + '">';
                        if (typeof searchOptions['type'] != 'undefined') {
                            if (searchOptions['type'][key] != '') {
                                html += '<span>' + $('#search-sidebar select[data-type="0"][data-group="' + key + '"]').find('option[value="' + searchOptions['type'][key] + '"]').text();
                            } else {
                                html += '<span>--';
                            }
                        }
                        html += '</span> / ';
                        if (typeof searchOptions['make_id'] != 'undefined') {
                            if (searchOptions['make_id'][key] != '') {
                                html += '<span>' + $('#search-sidebar select[data-type="1"][data-group="' + key + '"]').find('option[value="' + searchOptions['make_id'][key] + '"]').text() + '</span>';
                            } else {
                                html += '<span>--</span>';
                            }
                        }
                        html += '</div>';
                    }
                    html += '<span class="option-icon glyphicon pull-right"></span>';
                    $(s).html(html);
                } else {
                    //reloadSelects($('select[name="type"]'));
                    //reloadSelects($('select[name="make_id"]'));
                }
            }
        }

        $(document).on('click', 'button.remove-make-model', function (e) {
            e.preventDefault();
            var group = $(this).closest('div.make-model-item').find('ul[name="make_id[]"]').attr('data-group');
            if ($('div.popover li').length > 1) {
                var popover = $(this).closest('ul.search-options-popover').attr('data-popover');
                $('select[data-group="' + group + '"]').closest('div.make-model-item').remove();
                $('#search-sidebar select[data-group="' + group + '"]').closest('div.make-model-item').remove();

                if(popover == 'make-model') {
                    searchOptions['make_id'] = [];
                    searchOptions['model_id'] = [];

                    $('div.popover').find('select').each(function () {
                        if ($(this).val() !== "") {
                            addToSearchOptions($(this), 'select')
                        }
                    });

                    reloadMakeModel();
                } else if (popover == 'type-make') {
                    searchOptions['type'] = [];
                    searchOptions['make_id'] = [];

                    $('div.popover').find('select').each(function () {
                        if ($(this).val() !== "") {
                            addToSearchOptions($(this), 'select')
                        }
                    });

                    reloadTypeMake();
                } else if (popover == 'make_id') {
                    searchOptions['make_id'] = [];

                    $('div.popover').find('select').each(function () {
                        if ($(this).val() !== "") {
                            addToSearchOptions($(this), 'select')
                        }
                    });

                    reloadSelects($('select[name="make_id[]"]').first())
                }
            } else {
                if(searchOptions['make_id']!==undefined && Array.isArray(searchOptions['make_id']))
                    searchOptions['make_id'].splice(group, 1);

                if(searchOptions['model_id']!==undefined && Array.isArray(searchOptions['model_id']))
                    searchOptions['model_id'].splice(group, 1);

                if(searchOptions['type']!==undefined && Array.isArray(searchOptions['type']))
                    searchOptions['type'].splice(group, 1);


                //console.log(searchOptions);
                $('ul[data-group="' + group + '"]').closest('div.make-model-item').remove();
            }
        });

        function addToSearchOptions(elem, type) {
            var key = 0;
            var name = $(elem).attr('name');

            var vals = [];
            if (type == 'select') {
                $(elem).closest('div').find('[name="' + name + '"]').each(function () {
                    //text = $(this).find('option:selected').text();
                    if (name == 'make_id[]' || name == 'model_id[]' || name == 'type[]') {
                        if (name == 'make_id[]') {
                            name = 'make_id';
                        } else if (name == 'model_id[]') {
                            name = 'model_id';
                        } else if (name == 'type[]') {
                            name = 'type';
                        }
                        key = $(this).attr('data-group');
                        if (searchOptions[name] != undefined) {
                            vals[key] = searchOptions[name][key];
                            searchOptions[name][key] = $(this).val();
                        } else {
                            vals.push($(this).val());
                            searchOptions[name] = vals;
                        }
                    } else {
                        if ($(this).val() != undefined) {
                            if ($(this).val() === '') {
                                vals = '';
                                //console.log('check');
                            } else {
                                vals = $(this).val();
                            }
                        }
                        searchOptions[name] = vals;
                    }

                    if ($(elem).attr('name') == 'make_id[]') {
                        if($(this).closest('ul.search-params-list').find('select[name="model_id[]"]').length > 0) {
                            addToSearchOptions($(this).closest('ul.search-params-list').find('select[name="model_id[]"]'), 'select');
                        }
                        if(searchOptions['type'] == undefined || searchOptions['type'][key] == undefined) {
                            addToSearchOptions($(this).closest('ul.search-params-list').find('select[name="type[]"]'), 'select');
                        }
                    }
                    if ($(elem).attr('name') == 'type[]') {
                        addToSearchOptions($(this).closest('ul.search-params-list').find('select[name="make_id[]"]'), 'select');
                    }

                    key++;
                });
                //console.log(searchOptions);
                if (name == 'make_id[]' || name == 'model_id[]' || name == 'type[]') {
                    $('div#popover_content_make').html($('div.popover-content').html());
                }
            } else {
                if (vals == '') {
                    searchOptions[$(elem).attr('name')] = vals;
                }
                if (vals.length > 0) {
                    searchOptions[$(elem).attr('name')] = vals;
                } else if (vals.length == 0) {
                    searchOptions[$(elem).attr('name')][0] = '';
                }
            }
        }

        function reloadSelects(elem) {
            var name = $(elem).attr('name').replace('[]', '');
            var s = $('li span.title_' + name);
            if (typeof searchOptions[name] == 'undefined' || searchOptions[name] == '') {
                $(s).text('--');
            } else if ($.isArray(searchOptions[name])) {
                var array = [];
                $.each(searchOptions[name], function(index, value) {
                    var text = $(elem).find('option[value="' + searchOptions[name][index] + '"]').text();
                    if(text != '') {
                        array.push(text);
                    }
                })

                $(s).text(array.join(' / '));

            } else {
                $(s).text($(elem).find('option[value="' + searchOptions[name] + '"]').text());
            }
            if (name == 'country') {
                var city = $('#search-sidebar select[name="city"] option[value="' + searchOptions['city'] + '"]').text();
                if (city == '') {
                    searchOptions['city'] = '';
                }
                reloadSelects($('#search-sidebar select[name="city"]'));
            }
        }

        function selectQueryParams() {
            for (var i in searchOptions) {
                var target = '';
                $('input[name="' + i + '"]').attr("checked", false);
                $('input[name="' + i + '"]').parent().removeClass("selected");
                if ($.isArray(searchOptions[i])) {
                    for (var j in searchOptions[i]) {
                        target = $('input[name="' + i + '"][value="' + searchOptions[i][j] + '"]');
                        $(target).attr("checked", !$(this).attr("checked"));
                        $(target).parent().addClass("selected");

                        $('select[name="' + i + '[]"][data-group="' + j + '"] option').attr("selected", false);
                        target = $('select[name="' + i + '[]"][data-group="' + j + '"] [value="' + searchOptions[i][j] + '"]');
                        $(target).attr("selected", !$(this).attr("selected"));
                    }
                } else {
                    target = $('input[name="' + i + '"][value="' + searchOptions[i] + '"]');
                    $(target).attr("checked", !$(this).attr("checked"));
                    $(target).parent().addClass("selected");

                    $('select[name="' + i + '"] option').attr("selected", false);
                    target = $('select[name="' + i + '"] [value="' + searchOptions[i] + '"]');
                    $(target).attr("selected", !$(this).attr("selected"));
                }

            }
        }

        $(document).on('click', 'span.popover-close', function () {
            closePopover(this);
        });

        $(document).on('click', 'button[data-action="close-popover"]', function () {
            closePopover(this);
        });

        function closePopover(elem) {
            var target = $(elem).closest('div.popover').attr('id');
            $('[aria-describedby=\'' + target + '\']').popover('hide');
            $('div.modal-backdrop').remove();
        }

//$('body').on('click', function (e) {
//    $('[data-toggle="popover"]').each(function () {
//        //the 'is' for buttons that trigger popups
//        //the 'has' for icons within a button that triggers a popup
//        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
//            $(this).popover('hide');
//        }
//    });
//});
        //TODO: addToSearch doesnt work with index -> need to alter
        $('li[data-toggle="popover"]').popover({
            content: function () {
                selectQueryParams();
                return $('#popover_content_' + $(this).attr('data-option')).html();
            },
            trigger: 'manual',
            html: true,
            container: '#search-results',
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><span class="popover-close"><span class="icon-popover-close"></span></span><div class="popover-content"></div></div>',
        }).click(function () {
            if (!$(this).hasClass('remove')) {
                $(this).popover('toggle');
            }
        });
        $('[data-toggle="popover"]').on('show.bs.popover', function () {
            //$('#search-results div.popover').remove();
            $('#search-sidebar li[aria-describedby]').not($(this)).popover('hide');
            if ($('div.modal-backdrop').length == 0) {
                $('body').append('<div class="modal-backdrop fade in"></div>');
            }
        });
        $('[data-toggle="popover"]').on('hidden.bs.popover', function () {
            if ($('#search-sidebar li[aria-describedby]').length == 0) {
                $('div.modal-backdrop').remove();
            }
        });

        $(document).on('click', '#updateSearch', function (e) {
            e.preventDefault();
            var array = searchOptions;
            setSearch(array);
        });
        $(document).on('click', '#resetSearch', function (e) {
            e.preventDefault;
            var array = [];
            var array = originalOptions;
            setSearch(array);

        });
        function setSearch(array) {
            var uri = new URI();
            var newArray = [];
            for (var j in array) {
                if ($.isArray(array[j])) {
                    newArray[j + '[]'] = array[j];
                } else if (array[j] != null) {
                    newArray[j] = array[j];
                }
            }
            uri.search("");
            uri.setSearch(newArray);
            uri.removeSearch("page");
            window.location = uri.href();
        }
        $('.reset-search').on('click', function () {
            $('#row-start input').val('');
            $('#row-start .holder').each(function(){
                var input = $(this).closest('.categorySelect').find('input');
                if(input !== undefined && input.attr('name') != 'category_id')
                {
                    var string = $(this).closest('.categorySelect').find('button').html();

                    $(this).html(string);
                    if(input.attr('name')=='model_id[]')
                    {
                        $(this).closest('.categorySelect').find('ul')
                            .html('<li class="category-dropdown-item" data-value="" data-dropdown="true"><a><span>'+string+'</span></a></li>');
                    }
                }
            });
            if(document.URL.includes('skelbimai') || document.URL.includes('find')) {
                searchOptions = Object.keys(searchOptions).slice(0, 2).reduce((result, key) => {
                    result[key] = searchOptions[key];
                    return result;
                }, {});
            }
            else
                searchOptions = {};
        });
    });
})(jQuery);
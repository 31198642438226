jQuery.noConflict();
(function($) {
  $('.single-item').slick({ lazyLoad: 'ondemand' });
    $(function() {
        "use strict";
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        if (autoblock.booleans.hasFlash) {
            swal({
                title: autoblock.messages.flash_message.title,
                text: autoblock.messages.flash_message.text,
                type: autoblock.messages.flash_message.type,
                timer: 1700,
                html: true,
                showConfirmButton: false
            });
        }
        $.notify.addStyle('happyblue', {
            html: "<div><b><span data-notify-text='title'/></b><br>" +
                "<div data-notify-html='img' style='float:left;padding:5px;'/>" +
                "<div data-notify-html='review' style='float:right;padding:5px;'/>" +
            "</div>",
            classes: {
                base: {
                    //"white-space": "nowrap",
                    "background-color": "#df1d38",
                    "color": "#fff",
                    "padding": "5px",
                    "width": "auto",
                    "height": "125px",
                    "border-radius": "5px"
                },
                new: {
                    //"white-space": "nowrap",
                    "background-color": "#dda02a",
                    "color": "#fff",
                    "padding": "5px",
                    "width": "auto",
                    "height": "125px",
                    "border-radius": "5px"
                }
            }
        });

        //if (autoblock.urlaction.isMobile == 0) {
        //    var interval = setInterval(function() {
        //        $.ajax({
        //            url: autoblock.urlaction.getNewAdsInfo,
        //            success: function(response) {
        //                if(response !== '0') {
        //                    $.notify({
        //                            img: '<img src="'+ response.img +'" style="height: 80px;">',
        //                            title: 'Naujai įdėtas skelbimas',
        //                            aa: 'Naujai įdėtas skelbimas',
        //                            review: response.title + '<br>' +
        //                            response.price + 'EUR'
        //                        },
        //                        {
        //                            style: 'happyblue',
        //                            className: 'new',
        //                            clickToHide: false,
        //                            //autoHide: false,
        //                            position: "bottom right",
        //                            autoHideDelay: 10000
        //                        });
        //                    $('#mainNotify').attr('click', response.url);
        //                }
        //            }
        //        });
        //
        //    }, 5000);
        //}
        $(document).on('click', '#mainNotify', function() {
            location.href = $(this).attr('click');
        });



        $('input.datepicker').datepicker({
            format: "yyyy-mm-dd",
            language: "lt",
            weekStart: 1
        });


        if (autoblock.booleans.hasFlashOverlay) {
            swal({
                title: autoblock.messages.flash_message_overlay.title,
                text: autoblock.messages.flash_message_overlay.text,
                type: autoblock.messages.flash_message_overlay.type,
                html: true,
                confirmButtonText: autoblock.trans.flash.buttons.confirm
            });
        }
        $('.multiple-item').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            variableWidth: true,
            lazyLoad: 'ondemand'
        });
        $(document).on("change", "[name]", function (e) {
            $(this).removeClass('err-glow');
            $(this).parent().removeClass('err-glow');
        });
        $(document).on('click', '.search-by-market', function (e) {
            $(this).prop('disabled', false);
            e.preventDefault();
            $('input[name="market"]').val($(this).attr('data-market'));
            $('#searchForm').submit();
        });
        var photosHash = function () {
            var hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            var pair = hash.split('=');
            if (pair.length < 2) {
                return false;
            }
            params[pair[0]] = pair[1];

            if (!params.hasOwnProperty('pid')) {
                return params;
            }
            params.pid = parseInt(params.pid, 10);
            return params;
        };
        if (autoblock.attachments !== undefined) {
            var itemsz = jQuery.parseJSON(autoblock.attachments);
            var newList = [];
            var count_images = $('.single-item div.slick-slide:not(.slick-cloned) img').length;
            for (var i = 0; i < count_images; i++) {
                newList[i] = {
                    src: autoblock.urlaction.showAttachment + '/large/' + itemsz[i],
                    type: 'image'
                };
            }
            newList.pop();

            const src = autoblock.urlaction.showAttachment + '/large/' + itemsz[0];
            const title = $('.slick-slide[data-index="0"]').attr('data-title') || 'test title';

            newList.push({
                src: '<div style="margin:auto; position: relative;display:flex;align-items:center;justify-content: center;max-width:850px" class="credit-zoom">' +
                    '<img style="width:100%;padding:40px 0;" src="'+src+'" alt=""/>' +
                    '<div class="tag" style="height:calc(100% - 80px); background:rgba(0,0,0,0.8); top:40px; width:100%; z-index:10; position: absolute; text-align: center;" >\n' +
                    '    <p class="credit-title" style="text-align: center; padding: 8px;  background-color:  #df1d38; color:white; font-size: 17px;" >\n' +
                    '        Labai norite šio automobilio, bet trūksta pinigų?\n' +
                    '    </p>\n' +
                    '    <p class="credit-subtitle" style="text-align: center; color:white; font-size: 15px;  margin-bottom: 35px" >\n' +
                    '        Šį '+title+', kaip ir bet kurį kitą, galite įsigyti išsimokėtinai!\n' +
                    '    </p>\n' +
                    '    <p class="credit-bullet">🎯 Paskolos automobiliui nuo 1000 € iki 25 000 €.</p>\n' +
                    '    <p class="credit-bullet">🎯 Nereikia laidavimo, informacijos apie automobilį.</p>\n' +
                    '    <p class="credit-bullet">🎯 Automobilio nuosavybės teisės priklauso Jums.</p>\n' +
                    '    <p class="credit-bullet">🎯 Nereikia pirmos įmokos.</p>\n' +
                    '    <p class="credit-bullet">🎯 Nereikia Kasko.</p>\n' +
                    '    <a href="https://www.altero.lt/lt/application/step2?utm_source=autobilis&utm_medium=autobilis-galerija" target="_blank">\n' +
                    '    <button  class=" btn connect-form-bt" style=" margin-top: 30px; width: 230px; background-color: #df1d38; color: white; text-decoration: none;">Pasidomėk automobilio paskola</button>\n' +
                    '    </a>\n' +
                    '</div>' +
                    '</div>',
                type: 'inline'
            });

            if ($(window).width() > 767) {
                var hasData = photosHash();
                if(hasData.pid > 0 && newList.length > (hasData.pid-1)) {
                    $.magnificPopup.open({
                        type: 'image',
                        callbacks: {
                            close: function(){
                                history.replaceState("", document.title, window.location.pathname);
                            },
                            change: function (item) {
                                history.replaceState("", document.title, window.location.pathname+'#pid='+(parseInt(item.index) + 1));
                                hitGemius(window.location.href);
                                ga('send', 'pageview', window.location.pathname + "#galerija");
                            }
                        },
                        items: newList,
                        gallery: {
                            enabled: true
                        }
                    },parseInt(hasData.pid)-1);
                }

                $(document).on('click', '.single-item div[data-index]', function () {
                    $.magnificPopup.open({
                        type: 'image',
                        callbacks: {
                            close: function(){
                                history.replaceState("", document.title, window.location.pathname);
                            },
                            change: function (item) {
                                history.replaceState("", document.title, window.location.pathname+'#pid='+(parseInt(item.index) + 1));
                                hitGemius(window.location.href);
                                ga('send', 'pageview', window.location.pathname + "#galerija");
                            }
                        },
                        items: newList,
                        gallery: {
                            enabled: true
                        }
                    },parseInt($(this).attr('data-index')));
                });
                $('.slick-slider').on('afterChange', function(event, slick, currentSlide){
                    history.replaceState("", document.title, window.location.pathname+'#slick='+parseInt(currentSlide));
                    //hitGemius(window.location.href);
                    //ga('send', 'pageview', window.location.pathname + "#galerija");
                });
                $(document).on('click', '.multiple-item div[data-index]', function () {
                    $('.single-item').slick('slickGoTo', parseInt($(this).attr('data-index')));
                });
            }
        }
        var timeout;
        var prevUrl = window.location.pathname;
        var newUrl = '';
        $(window).scroll(function () {
            var st = $(window).scrollTop() + $(window).height()/3;
            $('.is-gallery-active .advert-images-large li img').each(function () {
                var containerOffset = $(this).offset().top - $(this).height();
                var containerBottom = containerOffset + $(this).outerHeight();
                newUrl = window.location.pathname + '#mid=' + parseInt($(this).attr('data-index'));
                if (st > containerOffset && st < containerBottom && newUrl != prevUrl) {
                    window.clearTimeout(timeout);
                    history.replaceState("", "", window.location.pathname + '#mid=' + parseInt($(this).attr('data-index')));
                    prevUrl = newUrl;
                    /*timeout = window.setTimeout(function () {
                        hitGemius(window.location.href);
                        ga('send', 'pageview', window.location.pathname + "#galerija");
                    },100);*/
                }
            });
        });

        function hitGemius(href,ref,id) {
            if (!id)
                var id = 'zNs7MrvC_DvNrjJrtZvGZdUKDkYNiRu8sIPLbiEvJ5..b7';
            gemius_hit(id);
        }
        $(document).on('submit', 'form[data-form-type="search"]', function (e) {
            $('select, input').each(function () {
                if ($(this).val() === "") {
                    $(this).attr('disabled', 'disabled');
                }
            });
        });
        $(document).on('click', "[data-category-id]", function(e){
            e.preventDefault();
            $(".set-category-id").val($(this).attr('data-category-id'));
            $("button[data-category-id]").removeClass('active');
            $(this).toggleClass('active');
            if(!$(this).closest('ul.category-dropdown').hasClass('noaction')) {
                changeSearchTemplate(parseInt($(this).attr('data-category-id')));
            } else {
                var text = $(this).text();
                $('span.title_category_id').html(text);
            }
        });
        function changeSearchTemplate(id) {
            //imageList = [];
            //imageInfo = [];
            //order = 0;
            //$(".err-glow").removeClass('err-glow');
            var view = $('#view-content');
            $('.alert-danger').hide();
            /*if (id == 8717 && view.attr('data-view-type') == 'quick') {
                location.href = autoblock.urlaction.search + '/' + id + '/Paslaugos';

                return;
            }*/
            var findText = '/find/';
            if (autoblock.locale == 'lt') {
                findText = '/skelbimai/';
            }
            if (view.attr('data-view-type') == 'quick') {
                $('#searchForm').attr('action', autoblock.urlaction.index + findText + autoblock.trans.categories[id]);
            } else {
                $('#full-search-form').attr('action', autoblock.urlaction.index + findText + autoblock.trans.categories[id]);
            }
            $('[data-toggle="popover"]').popover('hide');

            $.ajax({
                url: autoblock.urlaction.get_template + '/' + id + '/' + view.attr('data-view-type'),
                data: {'curr_url': window.location.href},
                method: 'GET',
                success: function (response) {
                    var url = window.location.href;
                    var path = window.location.pathname;
                    if(url.includes('skelbimai') || url.includes('find') || path == '/' || path =='/en/' || path=='/en' || path=='/ru/' || path =='/ru')
                    {
                        var row = $('#row-start');
                        row.html(response);
                    }
                    else
                    {
                        view.html(response);
                    }
                    $('input.datepicker').datepicker({
                        format: "yyyy-mm-dd",
                        language: "lt",
                        weekStart: 1
                    });
                },
                complete: function() {
                    $('li[data-toggle="popover"]').popover({
                        content: function () {
                            return $('#popover_content_' + $(this).attr('data-option')).html();
                        },
                        trigger: 'manual',
                        html: true,
                        container: '#search-results',
                        template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><span class="popover-close"><span class="icon-popover-close"></span></span><div class="popover-content"></div></div>',
                    }).click(function () {
                        if (!$(this).hasClass('remove')) {
                            $(this).popover('toggle');
                        }
                    });
                    $('[data-toggle="popover"]').on('show.bs.popover', function () {
                        //$('#search-results div.popover').remove();
                        $('#search-sidebar li[aria-describedby]').not($(this)).popover('hide');
                        if ($('div.modal-backdrop').length == 0) {
                            $('body').append('<div class="modal-backdrop fade in"></div>');
                        }
                    });
                    $('[data-toggle="popover"]').on('hidden.bs.popover', function () {
                        if ($('#search-sidebar li[aria-describedby]').length == 0) {
                            $('div.modal-backdrop').remove();
                        }
                    });
                }
            });
        }

        $(document).on('change', '.order-select', function (e) {
            e.preventDefault();
            var uri = new URI();
            uri.setSearch({
                order_by: $(this).val()
            });
            window.location = uri.href();
        });
        /*
            Drag'N'Drop Sortable photos
         */
        if (typeof Sortable === 'function')
        {
            var containerDrag = document.getElementById("preview-photos");
            var sortPhotos = Sortable.create(containerDrag, {
                animation: 150, // ms, animation spedred moving items when sorting, `0` — without animation
                dataIdAttr: 'data-att-id',
                draggable: '.draggable', // Specifies which items inside the element should be sortable
                onUpdate: function (){
                    $.ajax({
                        method: "POST",
                        url: autoblock.urlaction.attachments.sortAll,
                        data: {data: sortPhotos.toArray()},
                        success: function () {}
                    });
                }
            });


            var containerDrag = $('.newAdd-pictures .photos-list')
            // console.log(containerDrag);
            console.log('fragcontainer', containerDrag);
            // var sortPhotos = Sortable.create(containerDrag, {
            //     animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
            //     // dataIdAttr: 'data-att-id',
            //     // draggable: 'li', // Specifies which items inside the element should be sortable
            //     // onUpdate: function (){
            //     //     $.ajax({
            //     //         method: "POST",
            //     //         url: autoblock.urlaction.attachments.sortAll,
            //     //         data: {data: sortPhotos.toArray()},
            //     //         success: function () {}
            //     //     });
            //     // }
            // });
        }


        $(document).on('click', '#add-photos-f', function (e) {
            e.preventDefault();
            $('#addPhotos').click();
        });
        $(document).on('click', '#add-photos-more', function (e) {
            var ln = maxImages;
            if(ln < 18)
            {
                maxImages = 18;
                $('.placeholder.hidden').removeClass('hidden');
            }
        });
        $(document).on('change', 'input[name="services[payment_extra_photos]"]', function() {
            var ln = maxImages;
            if(ln < 18)
            {
                maxImages = 18;
                $('.placeholder.hidden').removeClass('hidden');
            }
        });
        $(this).on("click", "[data-image]", function () {
            var selectedLi = parseInt($(this).parent().parent().attr('data-att-id'));
            switch ($(this).data('image')) {
                case "rotate":
                    $.ajax({
                        method: "POST",
                        url: autoblock.urlaction.attachments.actions,
                        data: {id: selectedLi, action: 'rotate'},
                        beforeSend: function () {
                            $('li[data-att-id="' + selectedLi + '"] img').attr('src', autoblock.asset.a_loader);
                        },
                        success: function (response) {
                            $('li[data-att-id="' + selectedLi + '"] img').attr('src', response);
                        }
                    });
                    break;
                case "left":
                    $.ajax({
                        method: "POST",
                        url: autoblock.urlaction.attachments.actions,
                        data: {id: selectedLi, action: 'left'},
                        success: function (response) {
                            $('li[data-att-id="' + selectedLi + '"]:visible').insertBefore($('li[data-att-id="' + response + '"]:visible'));
                        }
                    });
                    break;
                case "right":
                    $.ajax({
                        method: "POST",
                        url: autoblock.urlaction.attachments.actions,
                        data: {id: selectedLi, action: 'right'},
                        success: function (response) {
                            $('li[data-att-id="' + selectedLi + '"]:visible').insertAfter($('li[data-att-id="' + response + '"]:visible'));
                        }
                    });
                    break;
            }
        });
        function sortByOrder(n) {
            var childCount = $(document).find("#preview-photos").children('li[data-order]').length;
            if (childCount >= n) {
                $(document).find(".photos-list li[data-position]").sort(sort_li).appendTo('.photos-list');
            }
        }

        function sort_li(a, b) {
            return parseInt($(b).attr('data-position')) < parseInt($(a).attr('data-position')) ? 1 : -1;
        }
        var imageList = [];
        var imageInfo = [];
        var order = 0;
        var maxImages = $('#preview-photos li.placeholder.hidden').length ? 6 : 18;
        $('#preview-photos').find('li[data-order]').each(function () {
            var boo = $(this).find('span[data-remove-id]');
            $(this).attr('data-order', order);
            boo.attr('data-remove-id', order);
            imageInfo[order] = {angle: 0, order: order, oldId: parseInt(boo.attr('data-remove-old'))};
            imageList[order] = undefined;
            order++;
        });
        $(document).on("change", "#addPhotos", function (e) {
            var files = e.target.files;
            var lengthF = files.length;
            var i = 0;
            var $form = $(this).closest('form');
            for (i; i < lengthF; i++) {
                if (files[i].size <= 10485760) {
                    if (files[i].type.match('image.*')) {
                        if (order < maxImages) {
                            if(order >= 17 && $('input[name="services[payment_extra_photos]"]').prop('checked') == false) {
                                $('input[name="services[payment_extra_photos]"]').prop('checked', true);
                                $('input[name="services[payment_extra_photos]"]').trigger('change');
                            }
                            imageList[order] = files[i];
                            imageInfo[order] = {angle: 0, order: order, oldId: null};
                            var forms = new FormData();
                            forms.append("imageList", imageList[order], imageList[order].name.toLowerCase());
                            forms.append("imageInfo[angle]", imageInfo[order].angle);
                            forms.append("imageInfo[order]", imageInfo[order].order);
                            forms.append("imageInfo[temp]", $form.find('#temp').attr('value'));
                            if (typeof (autoblock.advert_id) !== 'undefined') {
                                forms.append("imageInfo[advert_id]", autoblock.advert_id);
                            }
                            (function (key) {
                                $.ajax({
                                    url: autoblock.urlaction.attachment,
                                    method: "POST",
                                    data: forms,
                                    beforeSend: function () {
                                        $('.progress[data-order="' + key + '"]').show();
                                    },
                                    xhr: function () {
                                        var xhr = $.ajaxSettings.xhr();
                                        xhr.upload.onprogress = function (e) {
                                            // For uploads
                                            if (e.lengthComputable) {
                                                var progressState = Math.round((e.loaded / e.total) * 100);
                                                $('.progress[data-order="' + key + '"] > .progress-bar').css('width', progressState + '%').html(progressState + '%');
                                                if (progressState == 100) {
                                                    setTimeout(function () {
                                                        $('.progress[data-order="' + key + '"] > .progress-bar').css('width', progressState + '%').html(autoblock.trans.flash.misc.processing);
                                                    }, 500);
                                                }
                                            } else {
                                                $('.progress[data-order="' + key + '"] > .progress-bar').css('width', '100%').html(autoblock.trans.flash.misc.processing);
                                            }
                                        };
                                        return xhr;
                                    },
                                    processData: false,
                                    contentType: false,
                                    success: function (response) {
                                        var img = new Image();
                                        img.src = response.src;
                                        img.onload = function () {
                                            var image = '<li class="draggable" data-position="' + (key) + '" data-order="' + (key) + '" data-att-id="' + response.id + '"><span class="close-button">&nbsp;</span><img src="' + this.src + '" /><div data-order="' + key + '"><span class="round-icn rotate" data-image="rotate">&nbsp;</span><span class="round-icn left-a" data-image="left">&nbsp;</span><span class="round-icn right-a" data-image="right">&nbsp;</span> </div></li>';
                                            $form.find('.placeholder').first().replaceWith(image);
                                            $form.find('.progress[data-order="' + key + '"]').hide();
                                            // sortByOrder(lengthF);
                                        };
                                    },
                                    error: function (response) {
                                        var badImg = '../../images/bad-image2.png';
                                        //var image = '<li data-position="' + (key) + '" data-order="' + (key) + '"data-att-id="' + response.id + '"><span class="close-button">&nbsp;</span><img src="' + this.src + '" /><div data-order="' + key + '"><span class="round-icn rotate" data-image="rotate">&nbsp;</span><span class="round-icn left-a" data-image="left">&nbsp;</span><span class="round-icn right-a" data-image="right">&nbsp;</span> </div></li>';
                                        var image = '<li data-position="' + (key) + '" data-order="' + (key) + '"data-att-id="' + response.id + '" class="draggable"><span class="close-button">&nbsp;</span><img src="'+badImg+'" style="width: 100%; height: 100%;"> <div data-order="' + key + '" class="func-buttons" ><span class="round-icn rotate" data-image="rotate">&nbsp;</span></div></li>';
                                        $form.find('.placeholder').first().replaceWith(image);
                                        // sortByOrder(files.length);
                                        $form.find('.photoUploadLoading').hide();
                                    }
                                });
                            })(order);
                            order++;
                        }
                    } else {
                        swal({
                            title: autoblock.trans.flash.title.error,
                            text: autoblock.trans.flash.misc.file + " <b>" + files[i].name + "</b> " + autoblock.trans.flash.misc.not_photo,
                            type: "error",
                            html: true,
                            confirmButtonText: autoblock.trans.flash.buttons.confirm,
                        });
                    }
                } else {
                    swal({
                        title: autoblock.trans.flash.title.error,
                        text: autoblock.trans.flash.misc.file + " <b>" + files[i].name + "</b> " + autoblock.trans.flash.misc.too_big + "<br/> (" + autoblock.trans.flash.misc.allowed_size + ")",
                        type: "error",
                        html: true,
                        confirmButtonText: autoblock.trans.flash.buttons.confirm
                    });
                }

            }
            $(this).val(null);
        });
        $(document).on("change", "#addPhoto", function (e) {
            var file = e.target.files[0];
            var companyLogoForm = new FormData();
            companyLogoForm.append("image", file);
            $('.logo-placeholder').html('<img src="' + autoblock.asset.a_loader + '"/>');
            $.ajax({
                url: autoblock.urlaction.companyImage,
                method: "POST",
                data: companyLogoForm,
                processData: false,
                contentType: false,
                success: function (response) {
                    $('.logo-placeholder').show();
                    $('.logo-placeholder').html('<span class="close-button" id="close-photo-button">&nbsp;</span><img src="' + response + '"/>');
                }
            });
        });

        $(document).on("click", "#close-photo-button", function (e) {
            $('.logo-placeholder').hide();
            $('.logo-placeholder').html('<input type="hidden"  name="delete_photo" value="1">');
        });
        $(document).on("click", "#close-header-button", function (e) {
            $('.header-placeholder').hide();
            $('.header-placeholder').html('<input type="hidden"  name="delete_header" value="1">');
        });

        $(document).on("change", "#addHeader", function (e) {
            var file = e.target.files[0];
            var companyLogoForm = new FormData();
            companyLogoForm.append("header", file);
            $('.header-placeholder').html('<img src="' + autoblock.asset.a_loader + '"/>');
            $.ajax({
                url: autoblock.urlaction.companyHeader,
                method: "POST",
                data: companyLogoForm,
                processData: false,
                contentType: false,
                success: function (response) {
                    $('.header-placeholder').show();
                    $('.header-placeholder').html('<span class="close-button" id="close-header-button">&nbsp;</span><img src="' + response + '"/>');
                }
            });
        });
        $(document).on("click", ".photos-list li > .close-button", function (e) {
            if (typeof ($(this).parent().attr('data-att-id')) !== 'undefined') {
                var attID = parseInt($(this).parent().attr('data-att-id'));
                $.ajax({
                    url: autoblock.urlaction.destroyAttachment,
                    method: "POST",
                    data: {id: attID},
                    success: function (response) {
                        $.each($('li[data-att-id]'), function (key, value) {
                            if (typeof response[parseInt($(value).attr('data-att-id'))] != 'undefined') {
                                $(value).attr('data-order', response[parseInt($(value).attr('data-att-id'))]).attr('data-position', response[parseInt($(value).attr('data-att-id'))]);
                            } else {
                                $(value).remove();
                                var progressBar = '<div class="progress" style="display: none;" data-order="-1"><div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="min-width: 2em; width: 0%;">0%</div></div>';
                                $('#preview-photos').append('<li class="placeholder hidden" data-position="-1">' + progressBar + '<img src="' + autoblock.asset.photo + '" /></li>');
                            }
                        });
                        var l = response.length;
                        $.each($('.placeholder'), function (key, value) {
                            $(value).attr('data-position', l);
                            $(value).find('.progress').attr('data-order', l);
                            l++;
                        });
                        sortByOrder(maxImages);
                        order--;
                    },
                });
            }
        });
        if (typeof (autoblock.advert_id) != 'undefined') {
            $(window).unload(function () {
                $.ajax({
                    url: autoblock.urlaction.cleanTemp,
                    data: {advert_id: autoblock.advert_id},
                    method: "POST"
                });
            });
        }
        // var rules = '0';
        // $(document).on('change', '#rules', function () {
        //     if ($(this).prop('checked')) {
        //         rules = '1';
        //     } else {
        //         rules = '0';
        //     }
        // });
        var reg = false;
        $(document).on("click", "form .submitAdvertBtn, form #submitAdvertBtn", function (e) {
            console.log("submit form 1");
            e.preventDefault();
            $('.mobile-register-add').find('.country-selector').prop('disabled', true);
            $('.mobile-register-add').find('.city-selector').prop('disabled', true);
            var $this = $(this);
            $this.attr('disabled', true);
            var $form = $this.closest('form');
            $form.find('[data-key]').remove();

            var formSelector = '#' + $form.attr('id');
            var postUrl = '';
            if (formSelector == '#addAdvertForm') {
                postUrl = autoblock.urlaction.store;
            } else {
                postUrl = autoblock.update;
            }

            var fd = new FormData($form[0]);
            var rules = false;
            // if (rules == '1' || fd.get('rules') == '1') {
            //     fd.append('rules', true);
            // }
            fd.append("temp", $form.find('input[name="temp"]').val());
            $.ajax({
                url: postUrl,
                type: "POST",
                data: fd,
                processData: false,
                contentType: false,
                beforeSend: function () {
                    swal({
                        title: autoblock.trans.flash.title.wait,
                        imageUrl: autoblock.asset.a_loader,
                        showConfirmButton: false,
                        showCancelButton: false,
                        closeOnConfirm: false,
                    });
                },
                success: function (response) {
                    if (response.url) {
                        var userStatus = "Registruotas klientas";
                        if(response.isPhoneUser) {
                            userStatus = "Telefonu";
                        } else if (reg) {
                            userStatus = "Nauja registracija";
                        }
                        if (typeof ga !== 'undefined') {
                            ga('send', 'event', 'Skelbimo įdėjimas', userStatus);
                        }
                       window.location = response.url;
                    }
                },
                error: function (response) {
                    if (response.status == 422) {
                        swal.close();
                        $this.removeAttr('disabled');
                        $('.alert-danger').show();
                        var text = '';
                        for (var key in response.responseJSON) {
                            if(!$form.find("[name='" + key + "']").is('ul')) {
                                $form.find("[name='" + key + "']").addClass('err-glow');
                            }
                            var parent = $form.find("[name='" + key + "']").parent();
                            if ($(parent).hasClass('select-wrapper') === true) {
                                $(parent).addClass('err-glow');
                            }
                            text += '<li>' + response.responseJSON[key] + '</li>';
                        }
                        $('.alert-danger ol').html(text);
                    }
                    if(response.status == 401) {
                        swal({
                            title: autoblock.trans.flash.title.error,
                            text: "Jūsų sesija baigėsi. Prisijunkite iš naujo.",
                            type: "error",
                            html: true,
                            confirmButtonText: autoblock.trans.flash.buttons.confirm
                        });

                    }
                    if (response.status == 500) {
                        swal({
                            title: autoblock.trans.flash.title.error,
                            text: "Atsiprašome, apdorojant duomenis įvyko klaida. Prašome perkrauti puslapį ir iš naujo užpildyti duomenis.",
                            type: "error",
                            html: true,
                            confirmButtonText: autoblock.trans.flash.buttons.confirm
                        }, function (confirmButtonText) {
                            if (confirmButtonText == true) location.reload();
                        });
                    }
                },
                complete: function () {
                    $('.mobile-register-add').find('.country-selector').prop('disabled', false);
                    $('.mobile-register-add').find('.city-selector').prop('disabled', false);
                }
            });
            return false;
        });

        $(document).on('click', '#submit-contact', function (e) {
            e.preventDefault();
            $(this).hide();
            var res = grecaptcha.getResponse();
            $.ajax({
                method: "POST",
                url: autoblock.urlaction.contactSeller,
                data: {
                    message: $('textarea#message').val(),
                    senderemail: $('input[name=senderemail]').val(),
                    advert_id_seller: $('input[name=advert_id_seller]').val(),
                    phonenumber: $('input[name=phonenumber]').val(),
                    yourname: $('input[name=yourname]').val(),
                    recaptcha: res
                },
                beforeSend: function () {
                    swal({
                        title: autoblock.trans.flash.title.wait,
                        imageUrl: autoblock.asset.a_loader,
                        showConfirmButton: false,
                        showCancelButton: false,
                        closeOnConfirm: false,
                    });
                },
                success: function () {
                    window.location.reload();
                },
                error: function (response) {
                    swal.close();
                    $('#submit-contact').hide();
                    var text = '';
                    for (var key in response.responseJSON) {
                        $("[name='" + key + "']").addClass('err-glow');
                        var parent = $("[name='" + key + "']").parent();
                        if ($(parent).hasClass('select-wrapper')) {
                            $(parent).addClass('err-glow');
                        }
                    }
                    $('#submit-contact').show();
                }
            });
        });
        $(document).on('click', '[data-toggle=\'collapse\']', function () {
            if($(this).hasClass('category-dropdown-item')) {
                $($(this).next()).collapse('toggle');
                $(this).parent('ul.dropdown-menu').animate({scrollTop: $($(this).next()).offset().top},300);
            }
            if($(this).children('.fa').length)
            {
                $(this).children('.fa').toggleClass('fa-plus-square-o').toggleClass('fa-minus-square-o');
                var child = $(this).children('[data-text]');
                var childText = child.text();
                child.text(child.attr('data-text'));
                child.attr('data-text', childText);

            }
            return false;
        });

        $(document).on('click','#vehicle_make',function(){
            $('#vehicle_make, #vehicle_model').val('');
            $(this).closest(".dropdown").find(".holder").text('Markė');
            $('#vehicle_model').closest(".dropdown").find(".holder").text('Modelis');
        });

        $(document).on('click','#vehicle_model',function(){
            $('#vehicle_model').val('');
            $(this).closest(".dropdown").find(".holder").text('Modelis');
        });

        $(document).on('click', '[data-dropdown="true"]', function () {
            //e.preventDefault();
            //$('input[name=\'category_id\']').val($(this).attr('data-value'));
            //$('input[name=\'category_id\']').val($(this).attr('data-value'));
            var selectedOption = $(this).text().trim();
            var selectedValue = $(this).attr('data-value');

            var citiesWithBuyers = document.getElementsByClassName('cities-with-buyers');
            var input_name = $(this).closest(".dropdown").find("input").name;
            if(input_name == 'city' && citiesWithBuyers.length){
                var cityValues = Array.prototype.map.call(citiesWithBuyers, function(el) {
                    return el.value;
                });
                if(cityValues.includes(selectedValue)){
                    $( "#suggest_buyers_section" ).show();
                }else{
                    $( "#suggest_buyers_section" ).hide();
                }
            }

            var wrapper = $(this).closest(".dropdown").find(".select-wrapper");
            if(selectedValue == "" && $(this).closest(".dropdown").find("input").attr('required'))
                $(this).closest(".dropdown").find(".select-wrapper").addClass('err-glow');
            if(selectedValue != "" && wrapper.hasClass('err-glow'))
                $(this).closest(".dropdown").find(".select-wrapper").removeClass('err-glow');

            $(this).closest(".dropdown").find(".holder").text(selectedOption);
            $(this).closest(".dropdown").find("input").val(selectedValue);
            if(searchOptions!==undefined) {
                if (searchOptions["model_id"] !== undefined && $(this).closest(".categorySelect").find('ul.dropdown-menu').attr('name')=="make_id[]"){
                        var key = $(this).closest(".categorySelect").find('ul.dropdown-menu').attr('data-group');
                        if (searchOptions["model_id"][key] !== undefined)
                            searchOptions["model_id"][key] = '';
                }
                if ($(this).closest(".categorySelect").find('ul.dropdown-menu').attr('name')=="make_id"){
                    if(searchOptions["model_id"] !== undefined)
                        searchOptions["model_id"] = '';
                    if($(this).closest(".dropdown").find("input").attr('required'))
                        $('ul[name="model_id"]').closest(".dropdown").find(".select-wrapper").addClass('err-glow');
                }
                if (searchOptions["make_id"] !== undefined && $(this).closest(".categorySelect").find('ul.dropdown-menu').attr('name')=="type[]"){
                    var key = $(this).closest(".categorySelect").find('ul.dropdown-menu').attr('data-group');
                    if (searchOptions["make_id"][key] !== undefined)
                        searchOptions["make_id"][key] = '';
                }
                if ($(this).closest(".categorySelect").find('ul.dropdown-menu').attr('name')=="type"){
                    if (searchOptions["make_id"] !== undefined)
                        searchOptions["make_id"] = '';
                    if($(this).closest(".dropdown").find("input").attr('required') && $('.categorySelect input[name="make_id"]').val() == '')
                        $('ul[name="make_id"]').closest(".dropdown").find(".select-wrapper").addClass('err-glow');
                }
            }

            //changeSearchTemplate($('input[name=\'category_id\']'));
        });
        $(document).on('change', ".custom-select",function(){
            var selectedOption = $(this).find(":selected").text();
            $(this).next(".holder").text(selectedOption);
        }).trigger('change');
        var payments = [];
        $(document).on('click', '.select-payment', function(){
            $('.panel').removeClass('panel-picked');
            $('.panel[data-payment="'+$(this).data('payment')+'"]').addClass('panel-picked');
            $('#plan, #planMobile').val($(this).data('payment')).trigger('change');
            // $('#planMobile').val($(this).data('payment')).trigger('change');
            $('.services-itemcontent input[type="checkbox"]:not([disabled="disabled"])').removeAttr('checked');
            $('.services-itemcontent select:not([disabled="disabled"])').val('');
            payments = [];
        });
        $(document).on('change', '.payment-js', function(){
            if($(this).attr('id') == 'plan' || $(this).attr('id') == 'planMobile')
            {
                updatePrice({ plan: $(this).val() });
            }
            else
            {
                $('.panel').removeClass('panel-picked');
                $('#plan, #planMobile').val('0');
                var p_key = $(this).attr('name');
                var p_val = $(this).val();
                var index = payments.indexOf(p_key);
                if($(this).is(":checkbox:checked") || $(this).is("select"))
                {
                    payments.push({name: p_key, value: parseInt(p_val)});
                    if(p_val == "")
                    {
                        payments.push({name: p_key, value: 0});
                    }
                }
                else
                {
                    payments = $.grep(payments, function(e){ return e.name !== p_key; });
                }
                updatePrice(payments);
            }

        });
        function updatePrice(orders)
        {
            $.ajax({
                method: "POST",
                url: autoblock.urlaction.getTotal,
                data: orders,
                success: function(response)
                {
                    $(".amountOfServices span").html(response);
                    $("#price-pay").html(response);
                }
            });
        }

        function getSearchTitle(v) {
            $.ajax({
                url: autoblock.urlaction.get.searchTitle + '/' + v.attr('data-search-id'),
                method: 'GET',
                success: function (response) {
                    saveSearch(v, response);
                }
            });
        }

        function saveSearch(v, title) {
            if (typeof (v.attr('data-form-select')) !== 'undefined') {
                var ser = $(v.attr('data-form-select')).find('select,input').filter(function () {
                    return $(this).val() !== "";
                }).serialize();

            } else {
                var ser = autoblock.query;
            }
            swal({
                title: autoblock.trans.flash.misc.search_save,
                text: autoblock.trans.flash.misc.enter_search_title,
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top",
                inputPlaceholder: autoblock.trans.flash.misc.search_title,
                inputValue: title,
            }, function (inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError(autoblock.trans.errors.empty);
                    return false
                }
                $.ajax({
                    method: "POST",
                    url: autoblock.urlaction.saveSearch,
                    data: {serialized: ser, title: inputValue, search_id: v.attr('data-search-id')},
                    success: function (response) {
                        swal({
                            title: response.title,
                            text: response.message,
                            type: "success",
                            timer: 1300,
                            showConfirmButton: false
                        }, function () {
                            window.location.reload();
                        });
                    },
                    error: function (response) {
                        var text = response.responseJSON;
                        swal({
                            title: text.title,
                            text: text.message,
                            type: "error",
                            timer: 1300,
                            showConfirmButton: false
                        }, function () {
                            window.location.reload();
                        });
                    }
                });
            });
        }

        $(document).on('click', '.saveSearch', function (e) {
            e.preventDefault();
            if (autoblock.auth.guest !== true) {
                getSearchTitle($(this));
            } else {
                $('#login-modal').modal('show');
            }
            return false;
        });
        var searchOptions = autoblock.options;
        var originalOptions = autoblock.originalOptions;
        $(document).on('click', '.resetSearch', function (e) {
            e.preventDefault;
            var array = [];
            if(originalOptions !== undefined) {
                array = originalOptions;
            }
            setSearch(array);
            return false;

        });
        function setSearch(array) {
            var uri = new URI();
            var newArray = [];
            for (var j in array) {
                if ($.isArray(array[j])) {
                    //if(j == "make_id" || j == "model_id") {
                    newArray[j + '[]'] = array[j];
                } else if (array[j] != null) {
                    if (j == "make_id" || j == "model_id") {
                        newArray[j + '[]'] = [];
                        newArray[j + '[]'].push(array[j]);
                    } else {
                        newArray[j] = array[j];
                    }

                }
            }
            uri.search("");
            uri.setSearch(newArray);
            uri.removeSearch("page");
            window.location = uri.href();
        }

            $(document).ready(function() {
                // $(document).on('click', '.select-payment', function(){
                //     $('.panel').removeClass('panel-picked');
                //     $('.panel[data-payment="'+$(this).data('payment')+'"]').addClass('panel-picked');
                //     $('#plan, #planMobile').val($(this).data('payment')).trigger('change');
                //     // $('#planMobile').val($(this).data('payment')).trigger('change');
                //     $('.services-itemcontent input[type="checkbox"]:not([disabled="disabled"])').removeAttr('checked');
                //     $('.services-itemcontent select:not([disabled="disabled"])').val('');
                //     payments = [];
                // });
                var url = window.location.href;
                if (autoblock.selectedValues) {
                    $.each(autoblock.selectedValues, function(index, value) {
                        var select = $('input[name="'+index+'"]');
                        var checkbox = $('input[type="checkbox"][name="'+index+'"]');
                        var hidden = $('input[type="hidden"][name="'+index+'"]');
                        if($(select).length) {
                            select.each(function(){
                                if ($(this).css('opacity') == 0 && $(this).parent('.select-wrapper')) {
                                    $(this).val(value);
                                    var v = $(this).closest('.select-wrapper').find('li[data-value="'+value+'"] a span').html();
                                    $(this).closest('.categorySelect').find('.holder').html(v);
                                    if($(this).prop('required') && $(this).val() != '')
                                        $(this).closest('.select-wrapper').removeClass('err-glow');
                                } else {
                                    $(this).val(value);
                                    if($(this).prop('required') && $(this).val() != '')
                                        $(this).removeClass('err-glow');
                                }
                            });
                        } else if ($(checkbox).length) {
                            $(checkbox).prop('checked', true)
                        } else if ($(hidden).length) {
                            $(hidden).val(value);
                            if ($('.js-main-trigger[data-type="' + index + '"]').length) {
                                $('button.js-main-trigger[data-type="' + index + '"]').html(
                                    $('a[data-id="' + value +'"][data-type="' + index + '"]').html()
                                );
                            } else {
                                //console.log('not found ' + index);
                            }
                        }
                    });
                }
                $('#plan, #planMobile').trigger('change');
            });

        $(document).on('click', '.edit__saved-search', function (e) {
            e.preventDefault();
            window.open(
                $(this).attr('data-url'),
                '_blank'
            );

        });

        /** Deactivate advert **/
        $(document).on('click', '.advert-change-state', function (e) {
            e.preventDefault();
            var elem = $(this);
            var id = $(this).attr('data-advert-id');
            var state = $(this).attr('data-advert-state');
            $.ajax({
                url: autoblock.urlaction.advertUpdateState,
                method: "POST",
                data: { advert_id: id, advert_state: state },
                success: function(response) {
                    if (response.redirect) {
                        window.location.href = response.redirect_url;
                    }
                    if(state == 1) {
                        $(elem).closest('div.search-rezult-content').addClass('inactive');
                        $(elem).attr('data-advert-state', 0);
                    } else if(state == 0) {
                        $(elem).closest('div.search-rezult-content').removeClass('inactive');
                        $(elem).attr('data-advert-state', 1);
                    }
                    $(elem).find('span').text(response.buttonText);
                }
            })
            //if(state == 1) {
            //    $('#deactivate-advert input[name="advert_id"]').val(id);
            //    $('#deactivate-advert input[name="advert_state"]').val(state);
            //    $('#deactivate-advert').modal('show');
        });

        $('.force-open').modal('show');

        $(document).on('click', 'button.delete-advert-modal', function(e) {
            e.preventDefault();
            var target = $(this).attr('data-target');
            $('#delete-advert input[name="id"]').val(target);
            $("#delete-advert").modal('show');
        })
        /** END OF Deactivate advert **/

        /** Login and register user AJAX ***/
        $(document).on('click', '.facebook-login', function(e) {
            e.preventDefault();

            var url = $(this).attr('href');
            window.open(url,"_blank","height=500,width=580,status=yes,toolbar=no,menubar=no,location=no");
            $('#login-modal').modal('hide');
            watchForLoggedUser();
        })

        function watchForLoggedUser() {
            var interval = setInterval(function() {
                $.ajax({
                    url: autoblock.urlaction.checkLogin,
                    success: function(response) {
                        if(response !== 'false') {
                            clearInterval(interval);
                            refreshHtmlForLoggedUser();
                        }
                    }
                })

            }, 2000);
        }

        function refreshHtmlForLoggedUser() {
            loadView('advert.templates.add-user-details', 'div#author_info');
            loadView('header', '#header');
            $('#rules').prop('checked', true);
            $('#rules').val(1);
        }

        function loadView(template, target) {
            $.ajax({
                data: { template: template },
                method: 'get',
                url: autoblock.urlaction.loadBladeView,
                success: function(response) {
                    $(target).html(response);
                }
            })
        }

        $(document).on('click', "#loginUser", function (e) {
            e.preventDefault();
            // console.log('dsffds');
            var thisForm = $(this).closest('#ajaxLoginForm');
            var email = thisForm.find('input[name=\'email\']').val();
            var password = thisForm.find('input[name=\'password\']').val();
            $('div#author_info .alert').remove();
            $.ajax({
                method: "post",
                url: autoblock.urlaction.loginTpl,
                data: {email: email, password: password},
                success: function (response) {
                    if (response == false) {
                        $('dl#author_info').append('<div class="alert alert-danger">' + autoblock.trans.auth.login_failed + '</div>');
                    } else {
                        $('div#author_info').html(response);
                        $('#header_auth').load(autoblock.asset.a_header);
                        $('#rules').prop('checked', true);
                        $('#rules').val(1);
                    }
                },
                error: function (response) {
                    if (response.status == 422) {
                        $('div#author_info').append('<div class="alert alert-danger">' + autoblock.trans.auth.login_failed + '</div>');
                    }
                }
            })
        });
        $('#addAdvert-loginForm').submit(function (ev) {
            ev.preventDeault();
        });
        $(document).on('click', "#showReg", function (e) {
            e.preventDefault();
            $('#ajaxLoginForm, #phone_panel').hide();
            $('#registerUserForm').show();
            $('.mobile-register-add').find('.country-selector').prop('disabled', false);
            $('.mobile-register-add').find('.city-selector').prop('disabled', false);
        });
        $(document).on('click', "#showRegTel", function (e) {
            e.preventDefault();
            $('#ajaxLoginForm, #registerUserForm').hide();
            $('#phone_panel').show();
        });
        $(document).on('click', "#showLogin", function (e) {
            e.preventDefault();
            $('#registerUserForm, #phone_panel').hide();
            $('#ajaxLoginForm').show();
        });
        $(document).on('click', '#updateAllAdverts', function(e) {
            e.preventDefault();
            $.ajax({
                method: "post",
                url: autoblock.urlaction.updateAllAdverts,
                dataType: "json",
                success: function (response) {
                    if (response.true) {
                        swal({
                            title: response.true,
                            type: "success",
                            timer: 1700,
                            showConfirmButton: false
                        });
                    } else if (response.false) {
                        swal({
                            title: autoblock.trans.flash.title.error,
                            type: "error",
                            timer: 1700,
                            showConfirmButton: false
                        });
                    }
                }
            });
        });
        $(document).on('click', '.activation-user-panel-btn', function(e) {
            e.preventDefault();
            $.ajax({
                method: "post",
                url: ($(this).attr('id') == 'activateAllAdverts' ? autoblock.urlaction.activateAllAdverts : autoblock.urlaction.deactivateAllAdverts),
                dataType: "json",
                success: function (response) {
                    if (response.true) {
                        location.reload();
                    } else if (response.false) {
                        swal({
                            title: autoblock.trans.flash.title.error,
                            type: "error",
                            timer: 1700,
                            showConfirmButton: false
                        });
                    }
                }
            });
        });
        $(document).on('click', '.delete-advert-guest', function(e) {
            e.preventDefault();
            swal({
                title: "Pranešimas",
                text: "Ar norite gauti ištrynimo nuorodą SMS žinute, į telefono numerį, kuris nurodytas skelbime, kurią paspaudus galėsite ištrinti šį skelbimą?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Taip, noriu gauti nuorodą",
                cancelButtonText: "Uždaryti",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function() {
                $.ajax({
                    method: "post",
                    url: autoblock.urlaction.sendDeleteLink,
                    data: {advert_id: autoblock.advert_id},
                    dataType: "json",
                    success: function (response) {
                        if (response.true) {
                            swal({
                                title: response.true,
                                type: "success",
                                closeOnConfirm: true,
                                showConfirmButton: true
                            });
                        } else if (response.false) {
                            swal({
                                title: autoblock.trans.flash.title.error,
                                text: response.false,
                                type: "error",
                                closeOnConfirm: true,
                                showConfirmButton: true
                            });
                        }
                    }
                });
            });
        });

        $(document).on('click', "#registerUser", function (e) {
            e.preventDefault();
            var thisForm = $(this).closest('#registerUserForm');
            var data = thisForm.find(':input').serialize();
            if (data.length < 10) {
                data = $('#registerUserForm').find('input, select').serialize();
            }
            $('dl#author_info .alert').remove();
            $.ajax({
                method: "post",
                url: autoblock.urlaction.register,
                data: data,
                success: function (response) {
                    $('div#author_info').html(response);
                    $('#header_auth').load(autoblock.asset.a_header);
                    reg = true;
                },
                error: function (response) {
                    if (response.status == 422) {
                        $('div#author_info_errors').html('');
                        for (var key in response.responseJSON) {
                            //$('#register_panel[name="' + key + '"]').addClass('err-glow');
                            $('div#author_info_errors').append('<div class="alert alert-danger">' + response.responseJSON[key] + '</div>');
                        }
                    }
                }
            });
        });

        /** END OF login and register user AJAX ***/

        /** Modal animations **/
        $(document).on('click', "#login_lost_btn, .login_lost_btn",function() {
            modalAnimate('lost-modal');
        });

        $(document).on('click', "#login_lost",function() {
            $('#lost-modal').modal('show');
        });
        $('#login_register_btn').click(function () {
            modalAnimate('register-modal');
        });
        $('#lost_login_btn').click(function () {
            modalAnimate('login-modal');
        });

        function modalAnimate(modalName) {
            var modal = $('div.modal.in');
            modal.modal('hide');
            $(modal).on('hidden.bs.modal', function() {
                if(modalName !== null) {
                    $('#' + modalName).modal('show');
                    modalName = null;
                }
            })
        }

        /** END OF modal animations **/

        $(document).on('click', ".holder",function() {
            var openSelect = function(selector){
                var element = $(selector)[0], worked = false;
                if (document.createEvent) { // all browsers
                    var e = document.createEvent("MouseEvents");
                    e.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    worked = element.dispatchEvent(e);
                } else if (element.fireEvent) { // ie
                    worked = element.fireEvent("onmousedown");
                }
            };
            openSelect($(this).prev());

        });

        $(document).on('click', '#get-newest', function (e) {
            e.preventDefault();
            $('#get-popular').parent().removeClass('active');
            $(this).parent().addClass('active');
            $.ajax({
                url: autoblock.urlaction.getNewestAdverts,
                success: function (response) {
                    //$('#advertisement').hide().show("slide", { direction: "left" }, 1500);
                    $('#advertisement').html(response);
                }
            });
        });

        $(document).on('click', '#get-popular', function (e) {
            e.preventDefault();
            $('#get-newest').parent().removeClass('active');
            $(this).parent().addClass('active');
            $.ajax({
                url: autoblock.urlaction.getPopularAdverts,
                success: function (response) {
                    $('#advertisement').html(response);
                }
            });
        })

        $(document).on('click', '#get-urgent', function (e) {
            e.preventDefault();
            $('#get-newest').parent().removeClass('active');
            $(this).parent().addClass('active');
            $.ajax({
                url: autoblock.urlaction.getUrgentAdverts,
                success: function (response) {
                    $('#advertisement').html(response);
                }
            });
        });

        $(document).on('click', '#get-special', function (e) {
            e.preventDefault();
            $('#get-newest').parent().removeClass('active');
            $(this).parent().addClass('active');
            $.ajax({
                url: autoblock.urlaction.getSpecialAdverts,
                success: function (response) {
                    $('#advertisement').html(response);
                }
            });
        });

        $(document).on('click', '#payUserAdverts', function(e){
            e.preventDefault();
            var payPrice = 0;
            $('.payment-js').each(function() {
                var p_val = $(this).val();
                var price_value = parseInt($(this).attr('data-value'));
                if(($(this).is(":checkbox:checked") || $(this).is("select")) && !$(this).is(':disabled'))
                {
                    if(p_val != "")
                    {
                        payPrice += parseInt(p_val)*price_value;
                    }
                }
            });
            if(payPrice > 0) {
                $('#profile-pay').submit();
            } else {
                swal({
                    title: autoblock.trans.flash.title.error,
                    text: 'Pasirinkite norimas paslaugas.',
                    type: "error",
                    timer: 1700,
                    showConfirmButton: false
                });
            }
        });

        $(document).on('click', '#clearServicesFields', function(e) {
            var data = [];
            data['services'] = [];
            e.preventDefault();
            $('input[type="checkbox"]:not([disabled])').prop('checked', false);
            $('.services-item select > option:selected').removeAttr('selected');

            payments = [];
            $("#price-pay").html('0 €')
        })
        var prc = 0;
        $(document).on('change', '.profile-pay', function(){
            var p_val = $(this).val();
            var price_value = parseInt($(this).attr('data-value'));
            if($(this).is(":checkbox:checked") || $(this).is("select"))
            {

                if(p_val != "")
                {
                    prc += parseInt(p_val)*price_value;
                }
            }
            else
            {
                prc -= parseInt(p_val)*price_value;
            }
            $('#price-pay').html(prc);
        });

        $(document).on('click', '#deactivate-advert-admin', function (e) {
            e.preventDefault();

            if (autoblock.auth.admin) {
                let advertId = $(this).data('id');

                $('#admin-deactivate-advert').modal('show');
                $('#admin-deactivate-advert').find('input[name="advert_id"]').val(advertId)
            }
        });

        $(document).on('click', '#delete-advert-admin', function (e) {
            e.preventDefault();

            if (autoblock.auth.admin) {
                let advertId = $(this).data('id');

                $('#admin-delete-advert').modal('show');
                $('#admin-delete-advert').find('input[name="advert_id"]').val(advertId)
            }
        });

        $(document).on('click', '#edit-delete-link-reminder', function (e) {
            e.preventDefault();

            $('#edit-delete-link-reminder2').modal('show');
        });

        $(document).on('click', '.open-login-modal', function (e) {
            e.preventDefault();

            $('.modal').modal('hide');
            $('#login-modal').modal('show');
        });

        $(document).on('click', '.reset-password', function (e) {
            e.preventDefault();

            $('.modal').modal('hide');
            $('#lost-modal').modal('show');
        });

        $(document).on('click', '#save-to-garage', function (e) {
            e.preventDefault();
            if (!autoblock.auth.guest) {
                var id = $(this).attr('data-id');
                console.log('ID is:' + id);
                saveToGarage(id, true);
            } else {
                $('#login-modal').modal('show');
            }
            return false;
        });

        $(document).on('click', '.save-to-garage', function (e) {
            e.preventDefault();
            if (!autoblock.auth.guest) {
                var id = $(this).attr('id');
                saveToGarage(id, false);
            } else {
                $('#login-modal').modal('show');
            }
            return false;
        });


        function saveToGarage(id, fromInside) {
            $.ajax({
                method: "post",
                url: autoblock.urlaction.addFavorite,
                data: {advert_id: id},
                dataType: "json",
                success: function (response) {
                    if ( fromInside ) {
                        if (response.true) {
                            $('#save-to-garage[data-id="'+id+'"]').replaceWith('<button class="save-rezult savedBtn" disabled="disabled">Išsaugotas</button>');
                        } else if (response.false) {
                            swal({
                                title: autoblock.trans.flash.title.error,
                                text: response.false,
                                type: "error",
                                timer: 1700,
                                showConfirmButton: false
                            });
                        }
                    } else {
                        if (response.true) {
                            swal({
                                title: response.title,
                                text: response.true,
                                type: "success",
                                timer: 1700,
                                showConfirmButton: false
                            });
                        } else {
                            swal({
                                title: autoblock.trans.flash.title.error,
                                text: response.false,
                                type: "error",
                                timer: 1700,
                                showConfirmButton: false
                            });
                        }
                    }
                }
            });
        }

        $(document).on('click', '.close_my-adverts', function (e) {
            e.preventDefault();
            var id = $(this).attr('data-type-id');
            var type = $(this).attr('data-type');
            var deleteLink = autoblock.urlaction.delete[type];
            swal({
                title: autoblock.trans.flash.misc.confirm_text,
                text: autoblock.trans.flash.misc.will_be_deleted[type],
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: autoblock.trans.flash.buttons.confirm_delete,
                cancelButtonText: autoblock.trans.flash.buttons.cancel,
                closeOnConfirm: false
            }, function () {
                $.ajax({
                    url: deleteLink + '/' + id,
                    method: "POST",
                    data: {_method: "DELETE"},
                    success: function (response) {
                        $('.item[data-type-id="' + id + '"]').remove();
                        swal({
                            title: response.title,
                            text: response.message,
                            type: "success",
                            html: true,
                            showConfirmButton: true,
                            confirmButtonText: autoblock.trans.flash.buttons.confirm,
                        }, function () {
                            window.location.reload();
                        });
                    }
                });
            });

        });

        $(document).on('click', '.add-new-model', function (e) {
            e.preventDefault();
            var cloned = $(this).closest('.make-model-item').clone();
            var dataGroup = parseInt(cloned.find('.dropdown-menu').attr('data-group'));
            dataGroup++;
            cloned.find('.dropdown-menu').attr('data-group', dataGroup);
            cloned.find('.categorySelect input').attr('data-group', dataGroup);
            $(this).removeClass('add-new-model').addClass('remove-make-model');
            $(this).find('img').attr('src', ($(this).find('img').attr('src').replace('addCircle-icon.png', 'recycle-grey-icon.png')));

            cloned.find('.model-s').html("<option value>" + autoblock.trans.adverts.select_model + "</option>");
            cloned.find('.categorySelect:has(ul[name="make_id[]"]) .holder').html(autoblock.trans.adverts.select_make);
            cloned.find('.categorySelect:has(ul[name="model_id[]"]) .holder').html(autoblock.trans.adverts.select_model);
            cloned.find('.categorySelect:has(ul[name="type[]"]) .holder').html(autoblock.trans.adverts.meta.type);
            cloned.find('ul[name="model_id[]"]').html('<li class="category-dropdown-item" data-value="" data-dropdown="true"><a><span>'+autoblock.trans.adverts.select_model+'</span></a></li>');

            if ($('body div.popover-content').length > 0) {
                $(this).closest('.main-information > ul').append(cloned);
                $('#search-options .main-information > ul').html($(this).closest('.search-options-popover').html());
            } else {
                $('.main-information').append(cloned);
            }
        });

        $(document).on('change', '#mark_all_details', function (e) {
            $("#view-content input:checkbox").prop('checked', $(this).prop("checked"));
        });

        $(document).on('click', '#addCat', function (e) {
            e.preventDefault();
            var cloned = $('#copyCategory').clone();
            cloned.find('button#addCat').remove();
            cloned.find('button.addRecycle').show();
            $('#pasteCategory').append(cloned.html());
        });
        $(document).on('click', '#addLangCat', function (e) {
            e.preventDefault();
            var cloned = $('#copyLang').clone();
            cloned.find('button#addLangCat').remove();
            cloned.find('button.addRecycle').show();
            $('#pasteLang').append(cloned.html());
        });
        $(document).on('click', '#addCityCat', function (e) {
            e.preventDefault();
            var cloned = $('#copyCity').clone();
            cloned.find('button#addCityCat').remove();
            cloned.find('button.addRecycle').show();
            $('#pasteCity').append(cloned.html());
        });
        $(document).on('click', '#addCity', function (e) {
            e.preventDefault();
            var cloned = $('#copyCity').clone();
            cloned.find('button#addCity').remove();
            cloned.find('button.addRecycle').show();
            $('#pasteCity').append(cloned.html());
        });


        $(document).on('click', '.addRecycle', function (e) {
            e.preventDefault();
            $(this).closest('div.parent_div').remove();
        });
        var mobileSize = false;
        // $(window).resize(function(){
        //     if($(this).width() < 768 && !mobileSize)
        //     {
        //         mobileSize = true;
        //         changeTemplate($(this).width());
        //     }
        //     else if($(this).width() >= 768 && mobileSize)
        //     {
        //         mobileSize = false;
        //         changeTemplate($(this).width());
        //     }
        // });

        function changeTemplate(windowSize)
        {
            $.ajax({
                url: autoblock.urlaction.changeTemplateOnResize,
                method: 'GET',
                data: { size: windowSize },
                success: function() {
                    document.location.reload(true);
                }
            })
        }
    });

    if (typeof(autoblock.car_details.car_details_meta) != 'undefined' && typeof(autoblock.car_details.car_details_categories) != 'undefined') {
        $(document).on('click', 'ul[name="car_details_categories"] li.category-dropdown-item', function () {
            var part = $(this).attr('data-value');
            var str = '<li class="category-dropdown-item" data-value="" data-dropdown="true"><a><span>--Tipas--</span></a></li>';
            for (var i in autoblock.car_details.car_details_meta[part]) {
                str += '<li class="category-dropdown-item" data-value="' + i + '" data-dropdown="true"><a><span>' + autoblock.car_details.car_details_meta[part][i] + '</span></a></li>';
            }
            if (part == '') {
                $('li span.title_car_details_categories').html('');
            }
            $('li span.title_car_details_meta').html('');
            $('ul[name="car_details_meta"]').closest('.categorySelect').find('.holder').html('--Tipas--');
            $('ul[name="car_details_meta"]').html(str);
        });
    }

    $(document).on('click', 'button.interested', function(e) {
        e.preventDefault();
        var elem = $(this);
        var status = $(this).data('status');
        var target = $(this).data('target');
        $.ajax({
            url: autoblock.urlaction.interested,
            method: 'post',
            data: {id: target, status: status},
            success: function() {
                if(status == 'on') {
                    $(elem).data('status', 'off');
                    $(elem).html('<i class="fa fa-thumbs-o-down"></i>Nebedomina');
                } else if (status == 'off') {
                    $(elem).data('status', 'on');
                    $(elem).html('<i class="fa fa-thumbs-o-up"></i>Domina');
                }
            },
            error: function() {
            }
        });
    });

    window.setInterval(function() {
        if($('input[name="_token"]').length > 0) {
            $.ajax({
                url: autoblock.urlaction.getToken,
                method: 'get',
                success: function(response) {
                    $('input[name="_token"]').val(response);
                }
            })
        }
    }, 7201000)
    $('.delete').click(function(){

        id = $(this).attr('data-article-id');
        article = $(this);

        $.post( autoblock.urlaction.delete.article_image, { article_id: id })
            .done(function( data ) {
                article.parent().remove();
            });

    });

    $(document).on('click', '.contactBtn', function(e)
        {
            e.preventDefault();
            return false;
        }
    );
    $(document).on('change', '.saved-search-subscribe', function(){
        var subscribe = false;
        if(this.checked)
        {
            subscribe = true;
        }
        $.ajax({
            url: autoblock.urlaction.subscribeSavedSearch,
            method: 'post',
            data: {searchid: $(this).data('search-id'), subscribe: subscribe},
            success: function() {}
        });
    });
    $(document).on('click', '.update-saved-search', function(e)
    {
        $.ajax({
            url: autoblock.urlaction.updateSearchedAt,
            method: 'post',
            data: {searchid: $(this).data('search-id')},
            success: function(response) {}
        });
    });
    // mobile add photos script

    $('#uploadInfosrautas').on('change', function(e) {
        var form = $(this).parent();
        var file = e.target.files[0];
        var data = new FormData();
        data.append("image", file);

        $.ajax({
            url: $(form).attr('action'),
            data: data,
            method: 'POST',
            processData: false,
            contentType: false,
            success: function(d) {
                $('.mce-btn.mce-open').parent().find('.mce-textbox').val(d).closest('.mce-window').find('.mce-primary').click();
                $(this).val('');
            }
        })
    })

     /*   var elem = $('#informationInputCompanyPageColor');
        elem.data('oldVal', elem.val());

        // Look for changes in the value
        elem.bind("propertychange change click keyup input paste", function(event){
            if (elem.data('oldVal') != elem.val()) {
                elem.data('oldVal', elem.val());
                var isOk  = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(elem.val());
                if ( !isOk && elem.val() !== '')
                    elem.css('border-color', 'red');
                else
                    elem.css('border-color', '#ccc');
            }
        });*/


    $('.suggestedAd-wraper ul li').click(function(){
        $(this).parent().find('li').removeClass('active');
        $(this).addClass('active');
    });

        $(document).on('click', 'ul[name="country"] li[data-dropdown="true"]', function () {

            var code = $(this).attr('data-value');
            var identifier = $(this).closest('ul[name="country"]').attr('data-target');
            var extraAttr = $(this).closest('ul[name="country"]').attr('extra');
            var extraString = '';
            var elem = $('ul[name="city"]');

            var str = '<li class="category-dropdown-item" data-value="" data-dropdown="true"><a><span>--</span></a></li>';
            for (var i in autoblock.trans.city[code]) {
                str += '<li class="city-group"><span>' + autoblock.trans.adverts.meta[i] + '</span><ul class="fake-select category-dropdown">';
                for (var j in autoblock.trans.city[code][i]) {
                    str += '<li class="category-dropdown-item" data-value="' + j + '" data-dropdown="true"><a><span>' + autoblock.trans.city[code][i][j] + '</span></a></li>';
                }
                str += '</ul></li>';
            }
            $('ul[name="city"]').closest('.categorySelect').find('.holder').html('--');

            $(elem).html(str);
        });

            $( ".saved-search-count" ).each(function( ) {
                if ( parseInt($(this).text()) >= 10000) {
                    $(this).css('font-size', '25px');
                }
            });


    $(document).on( "click" , '.registration-form-trigger', (function(e) {
        e.preventDefault();

        $('.registration-tab').toggleClass('is-hidden');

        if ($(this).find('i.fa-angle-down').length > 0) {
            $(this).find('i').removeClass('fa-angle-down').addClass('fa-angle-up');
        } else {
            $(this).find('i').removeClass('fa-angle-up').addClass('fa-angle-down');
        }

        return false;
    }));

    $(document).on('click', '#detail_search_link', HDUtils.subDetailSearch);
    setTimeout(function () {
        // $('.advert-wraper input.rules').on('click', function (ev) {
        //     alert('dsadasdsa');
        //     console.log("asdasdasdasda");
        // });
    },0);

    $(document).on('click', '.read-more .more-link', function() {
        var $el, $p;

        $el = $(this);
        $p  = $el.parent();
        $p.animate({
                "max-height": 9999
            });

        // fade out read-more
        $el.fadeOut();

        // prevent jump-down
        return false;

    });

    $('.dark-cover', window.parent.document).on('click', function(event) {
        $('.dark-cover').hide();
        $('.mk-popup', window.parent.document).removeClass('active');
        $('.mk-iframe iframe').remove();
        $('.offer-popup-container').hide();

        event.preventDefault();
    });

    function showDarkCover() {
        $('.dark-cover').show();
    }

    $('#closeOffer').click(function () {
        $('.dark-cover').hide();
        $('.offer-popup-container').hide();
    });

    $(document).on('click', '#send-offer', function (e) {
        e.preventDefault();
        $(this).hide();
        // var res = grecaptcha.getResponse();
        $.ajax({
            method: "POST",
            url: autoblock.urlaction.offer,
            data: {
                advert_id_seller: $('input[name=advert_id_seller]').val(),
                send_offer: 1,
            },
            beforeSend: function () {
                swal({
                    title: autoblock.trans.flash.title.wait,
                    imageUrl: autoblock.asset.a_loader,
                    showConfirmButton: false,
                    showCancelButton: false,
                    closeOnConfirm: false,
                });
            },
            success: function () {
                window.location.reload();
            },
            error: function (response) {
                swal.close();
            }
        });
    });

    $(document).on('click', '#cancel-offer', function (e) {
        e.preventDefault();
        $(this).hide();
        // var res = grecaptcha.getResponse();
        $.ajax({
            method: "POST",
            url: autoblock.urlaction.offer,
            data: {
                advert_id_seller: $('input[name=advert_id_seller]').val(),
                send_offer: 0,
            },
            beforeSend: function () {
                swal({
                    title: autoblock.trans.flash.title.wait,
                    imageUrl: autoblock.asset.a_loader,
                    showConfirmButton: false,
                    showCancelButton: false,
                    closeOnConfirm: false,
                });
            },
            success: function () {
                $('.dark-cover').hide();
                $('.offer-popup-container').hide();
                swal.close();
            },
            error: function (response) {
                swal.close();
            }
        });
    });

    $('.all-searches-list--item--close--button').on('click', function () {
        var cookieKey = $(this).data('cookie'),
            listItem = $(this).closest('li.all-searches-list--item');

        $.ajax({
            method: 'POST',
            url: '/ajax/search/remove-cookie/' + cookieKey,
            success: function (data) {
                listItem.remove();
            }
        })
    });

// Cookie search email subscription functionality
    $('.switchSearchedCookieEmail').on('change', function () {
        if (this.checked) {
            var cookieId = $(this).data('cookieId'),
                cookieTitle = $(this).data('cookieName'),
                searchCookieEl = $('#searched-cookie-email');

            searchCookieEl.modal('show');
            searchCookieEl.find('input[name="cookie_id"]').val(cookieId);
            searchCookieEl.find('input[name="title"]').val(cookieTitle);

            $(this).prop('checked', false);
        } else {
            deleteUserCookieSearch($(this).data('cookieId'));
        }
    });

    $('#saveCookieSearchForm').on('submit', function (e) {
        e.preventDefault();

        var data = {
            cookie_id: $(this).find('input[name="cookie_id"]').val(),
            title: $(this).find('input[name="title"]').val(),
            email: $(this).find('input[name="email"]').val()
        };

        saveUserCookieSearch(data)
    });

    function saveUserCookieSearch(data) {
        $.ajax({
            type: 'POST',
            data: data,
            url: '/ajax/search/save-cookie-search',
            success: function () {
                swal({
                    title: autoblock.trans.flash.title.success,
                    type: 'success',
                    timer: 1000,
                    showConfirmButton: false
                });

                $('#searched-cookie-email').modal('hide');
                $('#switchSearchedCookieEmail-' + data.cookie_id).prop('checked', true);
            }
        })
    }

    function deleteUserCookieSearch(cookie_id) {
        $.ajax({
            type: 'POST',
            data: {cookie_id: cookie_id},
            url: '/ajax/search/delete-cookie-search'
        })
    }

//  User bilies invoice generation
    $(document).on('click', '.generate-invoice-btn', function () {
        let orderId = $(this).data('order'),
            action = '/user/my-bilies/invoice-generate/' + orderId;

        $('form#generate-invoice-form').attr('action', action)
    });

    $(document).ready(function() {
        $('.summernote').summernote({
            height: 200,
            callbacks: {
                onImageUpload: function(files, editor, $welEditable) {
                    sendFile(files[0], editor, $welEditable);
                },
                onMediaDelete: function(target) {
                    deleteFile(target[0].src);
                }
            }
        });

        function sendFile(file, editor, welEditable) {
            data = new FormData();
            data.append("file", file);
            $.ajax({
                data: data,
                type: "POST",
                url: '/user/upload/',
                cache: false,
                processData: false,
                contentType: false,
                success: function(url) {
                    if (url == 'error') {
                        $('#summernote_additional').summernote('insertText', 'An error occurred while trying to upload the image!');
                    }
                    else {
                        var image = $('<img>').attr('src', url);
                        $('#summernote_additional').summernote("insertNode", image[0]);
                    }
                }
            });
        }

        function deleteFile(src) {
            $.ajax({
                data: {src : src},
                type: "POST",
                url: '/user/delete/',
                cache: false,
                success: function(resp) {
                    if (resp == 'error') {
                        console.log('An error occurred while trying to delete the image!');
                    }
                }
            });
        }
    })

    $('.invoice-timeout-btn').on('click', function () {
        swal({
            title: autoblock.trans.flash.misc.invoice_timeout,
            type: 'error',
            showConfirmButton: true
        });
    })

})(jQuery);

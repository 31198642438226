(function (window) {

    function _subDetailSearch() {
        var url = _getFastSearchUrl();
        f = document.forms['quick-search'];

        if (url.length > 0)
            url = document.getElementById('detail_search_link').href + "?" + url.substr(1);
        else
            url = document.getElementById('detail_search_link').href;

        document.getElementById('detail_search_link').href = url;
    }

    function _getFastSearchUrl() {
        var exclude 	= [ "make_id", "model_id", "cats", "has_photo"];
        var url = '';
        f = document.forms['quick-search'];

        if(f) {
            for (i=0;i<f.elements.length;i++)
            {
                if(''!= f.elements[i].value &&  (-1 == jQuery.inArray(f.elements[i].name, exclude )))
                {
                    url += "&" + f.elements[i].name + "=" + f.elements[i].value;
                }
            }
            if(f.make_id && f.make_id.value> 0)
            {
                make_id  = f.make_id.value;
                model_id = f.model_id ?  f.model_id.value : '';
                url += "&make_id=" + make_id;
                if(model_id > 0)
                    url += "&model_id=" + model_id;
            }
        }
        return url;
    }


    window.HDUtils = {
        subDetailSearch: _subDetailSearch
    };
})(window);


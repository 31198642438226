(function($) {
    $.fn.extend({
        stickyMojo: function(options) {

            var settings = $.extend({
                'footerID': '',
                'contentID': '',
                'wrapperSelector': $('div.content-wraper'),
                'mokilizingasID': $('div#mokilizingas').outerHeight(true),
                'orientation': $(this).css('float'),
                'adSideBarHeight': $('div#adSideBar').outerHeight(true),
                'resizeCheck': '0',
                'maxResizeCheck': '5',
            }, options);

            var sticky = {
                'el': $(this),
                'stickyLeft': $(settings.contentID).outerWidth() + $(settings.contentID).offset.left,
                'stickyTop2': $(this).offset().top,
                'stickyHeight': $(this).outerHeight(true),
                'stickyWidth': $(this).outerWidth(true),
                'contentHeight': $(this).outerHeight(true),
                'wrapperMinHeight': $(this).outerHeight(true),
                'win': $(window),
                'breakPoint': $(this).outerWidth(true) + $(settings.contentID).outerWidth(true),
                'marg': parseInt($(this).css('margin-top'), 10)
            };

            var errors = checkSettings();
            cacheElements();

            return this.each(function() {
                buildSticky();
                checkForChanges();
            });

            function buildSticky() {
                if (!errors.length) {
                    if(settings.contentID !== '') {
                        if (settings.adSideBarHeight > sticky.stickyHeight) {
                            settings.contentID.css('min-height', settings.adSideBarHeight);
                        } else {
                            settings.contentID.css('min-height', sticky.stickyHeight);
                        }
                        settings.wrapperSelector.css('min-height', sticky.stickyHeight);
                    }
                    sticky.el.css('left', sticky.stickyLeft);

                    sticky.win.bind({
                        'scroll': stick,
                        'resize': function() {
                            sticky.el.css('left', sticky.stickyLeft);
                            stick();
                        }
                    });
                } else {
                    if (console && console.warn) {
                        console.warn(errors);
                    } else {
                        alert(errors);
                    }
                }
            }

            function checkForChanges()
            {
                if (settings.resizeCheck < settings.maxResizeCheck) {
                    if ($('div#adSideBar').outerHeight(true) > settings.adSideBarHeight)
                    {
                        settings.adSideBarHeight = $('div#adSideBar').outerHeight(true);
                        buildSticky();
                    }
                    settings.resizeCheck++;
                    setTimeout(checkForChanges, 1000);
                }
            }

            // Caches the footer and content elements into jquery objects
            function cacheElements() {
                settings.footerID = $(settings.footerID);
                settings.contentID = $(settings.contentID);
            }

            //  Calcualtes the limits top and bottom limits for the sidebar
            function calculateLimits() {
                return {
                    limit: settings.footerID.offset().top - settings.mokilizingasID - 25 - sticky.stickyHeight,
                    windowTop: sticky.win.scrollTop(),
                    stickyTop: sticky.stickyTop2 - sticky.marg
                }
            }

            // Sets sidebar to fixed position
            function setFixedSidebar() {
                sticky.el.css({
                    position: 'fixed',
                    top: 0,
                    height: '100%',
                });
            }

            // Determines the sidebar orientation and sets margins accordingly
            function checkOrientation() {
                if (settings.orientation === "left") {
                    settings.contentID.css('margin-left', sticky.el.outerWidth(true) + 4);
                } else {
                    //sticky.el.css('margin-left', settings.contentID.outerWidth(true));
                }
            }

            // sets sidebar to a static positioned element
            function setStaticSidebar() {
                sticky.el.css({
                    'position': 'absolute',
                    height: 'auto',
                    //'margin-left': '0px'
                });
                settings.contentID.css('margin-left', sticky.el.outerWidth(true) + 4);
            }

            // initiated to stop the sidebar from intersecting the footer
            function setLimitedSidebar(diff) {
                sticky.el.css({
                    top: diff,
                    height: 'auto',
                });
            }

            //determines whether sidebar should stick and applies appropriate settings to make it stick
            function stick() {
                var tops = calculateLimits();
                var hitBreakPoint = tops.stickyTop < tops.windowTop && (sticky.win.width() >= sticky.breakPoint);

                if (hitBreakPoint) {
                    setFixedSidebar();
                    checkOrientation();
                } else {
                    setStaticSidebar();
                }
                if (tops.limit < tops.windowTop) {
                    var diff = tops.limit - tops.windowTop;
                    setLimitedSidebar(diff);
                }
            }

            // verifies that all settings are correct
            function checkSettings() {
                var errors = [];
                for (var key in settings) {
                    if (!settings[key]) {
                        errors.push(settings[key]);
                    }
                }
                ieVersion() && errors.push("NO IE 7");
                return errors;
            }

            function ieVersion() {
                if(document.querySelector) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
    });
})(jQuery);
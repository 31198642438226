(function ($, window) {
    $(function () {

        $('.toggle-categories').on('click', function (e) {
            var $this = $(this),
                $bufferText = $this.find('span').html();

            $this.html('<span>' + $this.data('text') + '</span><svg class="icon-9 collapse-icon"><use xlink:href="#icon-arrow"></use></svg>');
            $this.toggleClass('collapsed')
            $this.data('text', $bufferText.toString());

            $('.categories-content').slideToggle(250);
            $(this).toggleClass('active');

            e.preventDefault();
        });


        // Toggle Contacts / Address
        $('.menu-item').on('click', function (e) {
            $(this).parent().siblings('.active').removeClass('active');
            $(this).parent().toggleClass('active');

            e.preventDefault();
        });

        $(document).on('change', '.order-select', function (e) {
            e.preventDefault();
            var uri = new URI();
            uri.setSearch({
                order_by: $(this).val()
            });
            window.location = uri.href();
        });

    });


// =================NEW=================
    $('input').attr('autocomplete', 'off');
    // Disable link on active language
    $(document).on('click', '.lang-menu .active', function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    // Handle header menu hover
    if ($(window).width() > 768) {
        $('.site-header .fl').mouseover(function () {
            $(this).addClass('active');
        }).mouseout(function () {
            $(this).removeClass('active');
        })
    }

    var filter, ul, li, a, i, txtValue,
        options = [];

    var searchOptions = autoblock.options;
    // Handle input click
    $('body').click(function (e) {
        $(this).find('input').attr('autocomplete', 'off');
        if ($(e.target).is('input')) {
            var wrapper = $(e.target).closest('.select-wrapper');

            // $(e.target).val('');
            wrapper.addClass('open');
            wrapper.find('li').removeClass('current');
            wrapper.find('li').css('display', 'block').first().addClass('current');


            // Loop through list on input change and hide irrelevant items
            $(e.target).on('keyup', function (ev) {
                filter = $(this).val().toUpperCase();
                ul = $(this).closest('.select-wrapper').find('ul');
                li = ul.find('li');
                var arr = [];

                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                        arr.push(li[i]);
                    } else {
                        li[i].style.display = "none";
                    }
                }

                // first li active on input change
                if (arr.length && options.length !== arr.length) {
                    li.removeClass('current');
                    arr[0].classList.add('current');
                }

                options = arr;

            }).keydown(function (ev) {
                // handle enter key
                var wrapper = $(this).closest('.select-wrapper'),
                    holder = wrapper.closest('.select').find('.holder');

                if (ev.which == 13) {
                    ev.preventDefault();
                    ev.stopPropagation();


                    // remove input value if nothing matches
                    if (options.length === 0) {
                        $(this).val('');
                        holder.text(wrapper.find('ul').attr('data-ph'));
                    } else {
                        // set highlighted item
                        for (let i = 0; i < options.length; i++) {
                            if (options[i].classList.contains('current')) {
                                options[i].click();
                                holder.text(options[i].textContent);
                                $(this).val(options[i].getAttribute('data-value'));
                                $(this).closest('.select-wrapper').removeClass('open')
                            }
                        }
                    }
                }
            });
        }
    });


})(jQuery, window);

jQuery.noConflict();
(function ($) {
    $(function () {
        var debounce = true;
        var debounceTimeout;
        var debounceTime = 30;

        $(document).on('ready', function () {
            if ($('[name="model_id[]"]').length > 0) {
                $('[name="model_id[]"]')[0].disabled = false;
                if ($('[name="make_id[]"]').val() !== "" && $('[name="model_id[]"]').val() === "") {
                    $('[name="make_id[]"]').trigger('change');
                }
            }
        });

        $(document).on('click', 'ul li.category-dropdown-item', function () {
            if($(this).attr('noload') == undefined && $(this).closest('.categorySelect').find('input').attr('name')!='model_id[]') {
                var input = $(this).closest('.categorySelect').find('input');
                var i = +$(input).data('type');
                var j = +$(input).data('group');
                var loadingText = 'Kraunasi';
                var loadedText = 'Visi';
                var category = $('input#category_id').val();
                if(autoblock.language == 'en') {
                    loadingText = 'Loading';
                    loadedText = 'All';
                }
                else if(autoblock.language == 'ru')
                {
                    loadingText = 'Загрузка';
                    loadedText = 'Весь';
                }
                while ($('ul.dropdown-menu[data-type=' + (i + 1) + '][data-group=' + (j) + ']').closest('.categorySelect').find('input').val() !== undefined) {
                    $('ul.dropdown-menu[data-type=' + (i + 1) + '][data-group=' + (j) + ']').closest('.categorySelect').find('.holder').html(loadingText);
                    i++;
                }
                if ($('ul.dropdown-menu[data-type=' + (input.data('type') + 1) + '][data-group=' + input.data('group') + ']').closest('.categorySelect').find('input').val() !== undefined) {
                    var select = {
                        val: $(input).val(),
                        type: $(input).data('type'),
                        group: $(input).data('group'),
                        loadingText: loadingText,
                        loadedText: loadedText,
                        category: category,
                    };

                    /* Commented in order for existing model adverts filter to work */
                    // if (makesCache[select.val]) {
                    //     if (makesCache[select.val].cached) {
                    //         makeMakersSelect(select, makesCache[select.val].response);
                    //         return;
                    //     }
                    // }

                    if (debounce) {
                        showMakersLoading(select);
                        clearTimeout(debounceTimeout);
                        debounceTimeout = setTimeout(function() {
                            loadMakers({
                                val: select.val,
                                type: select.type,
                                group: select.group,
                                loadingText: select.loadingText,
                                loadedText: select.loadedText,
                                category: category,
                            });
                        }, debounceTime);
                    } else {
                        loadMakers({
                            val: select.val,
                            type: select.type,
                            group: select.group,
                            loadingText: select.loadingText,
                            loadedText: select.loadedText,
                            category: category,
                        });
                    }
                }
            }
        });

        var makesCache = {};

        function showMakersLoading(select) {
            var elem = $('ul.dropdown-menu[data-type=' + (select.type + 1) + '][data-group=' + (select.group) + ']').closest('.categorySelect').find('.holder');
            // var elem = $('select[data-type=' + (+select.data('type') + 1) + '][data-group=' + (+select.data('group')) + ']');
            $(elem).html(select.loadingText);
        }

        function makeMakersSelect(select, response) {
            if (response.length > 0) {
                $('ul.dropdown-menu[data-type=' + (select.type + 1) + '][data-group=' + select.group + ']').closest('.categorySelect').find('.holder').html(select.loadedText);
                var name = $('ul.dropdown-menu[data-type=' + (select.type + 1) + '][data-group=' + select.group + ']').attr('name');
                var string = '<li class="category-dropdown-item" data-value="" data-dropdown="true"><a><span>'+select.loadedText+'</span></a></li>';
                string += getList(response);
            }
            else{
                var name = $('ul.dropdown-menu[data-type=' + (select.type + 1) + '][data-group=' + select.group + ']').attr('name');
                var string = '<li class="category-dropdown-item" data-value="" data-dropdown="true"><a><span>'+select.loadingText+'</span></a></li>';
            }

            var elem = $('ul.dropdown-menu[data-type=' + (select.type + 1) + '][data-group=' + select.group + ']');
            elem[0].disabled = false;
            // var elem = $('select[data-type=' + (+select.data('type') + 1) + '][data-group=' + (+select.data('group')) + ']');
            $(elem).html(string);
        }

        function loadMakersAjax(select) {
            var id = select.val;
            var typeId = select.type + 1;
            if (makesCache[id]) {
                if (makesCache[id].getting) {
                    return;
                }
            }

            var rc = false;
            if ((select.type + 1) == 2) {
                rc = true;
            }

            if (!makesCache[id]) {
                makesCache[id] = {};
                makesCache[id]['retries'] = 0;
            }

            $.ajax({
                method: "get",
                url: autoblock.urlaction.datatype,
                data: {id: select.val, typeId: select.type + 1, recursion: rc, curr_url: window.location.href, category: select.category,},
                dataType: "json",
                success: function (response) {
                    makesCache[id]['getting'] = false;
                    makesCache[id]['cached'] = true;
                    makesCache[id]['success'] = true;
                    makesCache[id]['response'] = response;
                    makeMakersSelect(select, response);
                },
                error: function() {
                    makesCache[id]['cached'] = false;
                    makesCache[id]['success'] = false;
                    makesCache[id]['select'] = select;
                    makesCache[id]['getting'] = false;
                    makesCache[id].retries++;
                    if (makesCache[id].retries < 10) {
                        loadMakersAjax(makesCache[id].select);
                    } else {
                        // could get event after 10 retries
                    }
                }
            });
        }


        function loadMakers(select) {
            // var id = select.val();
            // var typeId = select.data('type') + 1;
            var id = select.val;
            var typeId = select.type;
            var group = select.group;
            var category = $('input#category_id').val();

            /* Commented in order for existing model adverts filter to work */
            // if (makesCache[id]) {
            //     if (makesCache[id].response && makesCache[id].success) {
            //         makeMakersSelect(select, makesCache[id].response);
            //         return;
            //     }
            // }

            if (!makesCache[id]) {
                makesCache[id] = {};
            }
            makesCache[id]['cached'] = false;
            makesCache[id]['success'] = false;
            makesCache[id]['select'] = {
                val: select.val,
                type: select.type,
                group: select.group,
                loadingText: select.loadingText,
                loadedText: select.loadedText,
                category: category,
            };
            makesCache[id]['retries'] = 0;
            loadMakersAjax(makesCache[id].select);
        }

        function getList(response) {
            var string = '';
            for (var i in response) {
                if (response[i].recursive_children.length > 0) {
                    if ($(document).find('#addAdvertForm').length == 0) {
                        string += '<li class="category-dropdown-item" data-value="' + response[i].id + '" data-dropdown="true"><a><span>' + response[i].title + '</span></a></li>';
                    } else {
                        string += '<li class="city-group"><span>' + response[i].title + '</span><ul class="fake-select category-dropdown">';
                    }
                    for (var j in response[i].recursive_children) {
                        string += '<li class="category-dropdown-item" data-value="' + response[i].recursive_children[j].id + '" data-dropdown="true"><a><span>' + response[i].recursive_children[j].title + '</span></a></li>';
                    }
                    string += '</ul>';
                } else {
                    string += '<li class="category-dropdown-item" data-value="' + response[i].id + '" data-dropdown="true"><a><span>' + response[i].title + '</span></a></li>';
                }
            }
            return string;
        }
    });
})(jQuery);